import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';
import { Button, FormHelperText, Grid, IconPlus, Loader, Token, Typography, Wrapper } from '@screentone/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { ResponsiveLoader, WidePageWrapper } from 'components';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  AllessehCollectionType,
  CollectionContentItem,
  PublicationSettingSearchableContentType,
  usePagesDtoForCollectionIdQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { useManageCollectionItems } from 'hooks/collections/useManageCollectionItems';
import { useSaveCollection } from 'hooks/collections/useSaveCollection';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes } from 'hooks/useAllessehContentQuery';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import styles from './CollectionEdit.module.scss';
import ContentCard from './components/collection-content-card/CollectionContentCard';
import { CollectionCurateSection } from './components/collection-curate-section/CollectionCurateSection';
import { CollectionInfoBoxesSection } from './components/collection-info-boxes-section/CollectionInfoBoxesSection';
import { CollectionItemsSection } from './components/collection-items-section/CollectionItemsSection';
import { SearchParams } from '../../components/search-content/SearchContent';
import { DRAGGABLE_PREFIXES } from '../../utils/collectionDragUtils';

export const CollectionEdit = () => {
  const { idCreatedUtc } = useParams();

  const {
    collection,
    hasCollectionChanged,
    hasRecentlyUpdatedNotificationText,
    isUpdating,
    isLoadingCollection,
    isRefetchingCollection,
    hasAllessehCollectionId,
    handlePartialCollectionChange,
    handleSave
  } = useSaveCollection(AllessehCollectionType.CurationCollection, idCreatedUtc);

  const currentProperty = useConvertedProperty();
  const { data: publicationSettingsResp } = usePublicationSettings();
  // search
  const collectionContentIds = useMemo(() => collection?.contentItems.map((c) => c.originId) ?? [], [collection]);
  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: collectionContentIds
    }),
    [collectionContentIds, publicationSettingsResp?.publicationSetting.defaultContentTypes]
  );
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: AllessehQuerySortKeyTypes.LiveDate,
    refreshTimestamp: Date.now()
  });

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (publicationSettingsResp) {
      newSearchParams.filters.contentTypes = publicationSettingsResp.publicationSetting.defaultContentTypes;
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationSettingsResp]);

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (
      newSearchParams.filters.contentTypes.length > 0 && // ensure contentTypes list has loaded and has at least 1 item
      !isEqual(newSearchParams.filters.excludedContentIds, collectionContentIds)
    ) {
      newSearchParams.filters.excludedContentIds = collectionContentIds;
      setSearchParams(newSearchParams);
    }
  }, [collectionContentIds, searchParams]);

  const handleSearchParamsChange = (newSearchParams: SearchParams) => {
    setSearchParams(newSearchParams);
  };

  const {
    data: pagesForCollectionData,
    isLoading: isLoad,
    fetchStatus
  } = usePagesDtoForCollectionIdQuery(
    { publicationKey: currentProperty!, collectionId: idCreatedUtc!.replace(/_\d{13}$/, '') },
    { enabled: !!currentProperty && !!idCreatedUtc }
  );
  const pagesAreLoading = isLoad && fetchStatus !== 'idle';

  // manage collection data
  const {
    handleAddContent,
    handleRemoveContent,
    handleDragEnd,
    handleDragStart,
    handleMoveContentFromQueriedToCurated,
    handleMoveContentFromCuratedToQueried,
    handleExcludeQueryItem
  } = useManageCollectionItems({
    collection,
    onPartialCollectionChange: handlePartialCollectionChange
  });

  if (!collection && !pagesAreLoading && !isRefetchingCollection && !isLoadingCollection) {
    return <ErrorPage type="404" />;
  }

  return (
    <PageRootContainer>
      <WidePageWrapper data-testid="collection-edit-container" padding={{ all: 'md' }}>
        {!collection ? (
          <ResponsiveLoader />
        ) : (
          <>
            <Wrapper
              data-testid="collection-edit-header"
              padding={{ top: 'md', bottom: 'md' }}
              className={styles.secondHeader}
            >
              <div>
                <Typography data-testid="collection-edit-title" variant="header2" margin={{ bottom: 'none' }}>
                  {collection.name}
                </Typography>
                {pagesForCollectionData && pagesForCollectionData.pagesDTOForCollectionId.length > 0 && (
                  <Token data-testid="collection-edit-status" margin={{ left: 'sm' }} color="emerald">
                    In Use
                  </Token>
                )}
                {hasRecentlyUpdatedNotificationText && (
                  <Typography data-testid="collection-edit-update" color="asphalt" margin={{ left: 'md' }}>
                    {hasRecentlyUpdatedNotificationText}
                  </Typography>
                )}
              </div>
              <div className={styles.saveButton}>
                <Button
                  primary
                  onClick={handleSave}
                  disabled={collection.isArchived || isUpdating || !hasCollectionChanged}
                  data-testid="collection-save-button"
                >
                  {isUpdating && <Loader size="md" margin={{ right: 'md' }} />}
                  Save
                </Button>
                {collection.isArchived && !isUpdating && hasCollectionChanged && (
                  <FormHelperText>You cannot update a collection when archived.</FormHelperText>
                )}
              </div>
            </Wrapper>
            <Wrapper data-testid="collection-edit-details" margin={{ top: 'sm' }}>
              <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                <Grid>
                  <Grid.Row xsCols={12}>
                    <Grid.Col xs={3}>
                      <CollectionInfoBoxesSection
                        collection={collection}
                        hasAllessehCollectionId={hasAllessehCollectionId}
                        onPartialCollectionChange={handlePartialCollectionChange}
                        pagesForCollection={pagesForCollectionData}
                        pagesAreLoading={pagesAreLoading}
                        hasCollectionChanged={hasCollectionChanged}
                      />
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <CollectionItemsSection
                        collection={collection}
                        onRemoveContent={handleRemoveContent}
                        onMoveContentFromQueriedToCurated={handleMoveContentFromQueriedToCurated}
                        onMoveContentFromCuratedToQueried={handleMoveContentFromCuratedToQueried}
                        onExcludeQueryItem={handleExcludeQueryItem}
                      />
                    </Grid.Col>
                    <Grid.Col xs={5}>
                      <CollectionCurateSection
                        searchProps={{
                          defaultFilterState,
                          searchParams,
                          onSearchParamsChange: handleSearchParamsChange,
                          renderContentCard: (content: AllessehContent, index: number) => (
                            <Draggable
                              draggableId={`${DRAGGABLE_PREFIXES.RESULTS}${JSON.stringify({
                                originId: content.data.id,
                                originContentType: content.data.type,
                                content: JSON.stringify(content)
                              } as CollectionContentItem)}`}
                              index={index}
                            >
                              {(draggableProvided, snapshot) => (
                                <div
                                  data-testid="collection-edit-card-content"
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  <ContentCard
                                    content={content}
                                    isDragging={snapshot.isDragging}
                                    renderActions={() => (
                                      <Button
                                        data-testid="collection-edit-card-add-button"
                                        tertiary
                                        icon={IconPlus as SvgComponent}
                                        onClick={() => handleAddContent(content)}
                                      >
                                        Add to Collection
                                      </Button>
                                    )}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        }}
                        queryProps={{
                          allessehJsonQuery: collection.allessehJsonQuery,
                          onPartialCollectionChange: handlePartialCollectionChange
                        }}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </DragDropContext>
            </Wrapper>
          </>
        )}
      </WidePageWrapper>
    </PageRootContainer>
  );
};
