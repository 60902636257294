import { Button, IconBarChartVer, IconImage, IconVideo } from '@screentone/core';

import { MediaTypes } from '../MediaInput.utils';

type Props = {
  variant: MediaTypes;
  isIdFormOpen: boolean;
  setIsIdFormOpen: (isOpen: boolean) => void;
  setVariant: (variant: MediaTypes | '') => void;
  disabled: boolean;
};

export const MediaInputButton = ({ variant, isIdFormOpen, setIsIdFormOpen, disabled, setVariant }: Props) => {
  const capitalizedVariant = variant.charAt(0).toUpperCase() + variant.slice(1);

  function getIcon() {
    switch (variant) {
      case 'image':
        return IconImage as SvgComponent;
      case 'video':
        return IconVideo as SvgComponent;
      case 'chart':
        return IconBarChartVer as SvgComponent;
      default:
        return IconImage as SvgComponent;
    }
  }

  return (
    <div data-testid="media-button-wrapper">
      <Button
        disabled={isIdFormOpen || disabled}
        tertiary
        data-testid={`media-button-${variant}`}
        icon={getIcon()}
        onClick={() => {
          setIsIdFormOpen(!isIdFormOpen);
          setVariant(variant);
        }}
      >
        Add {capitalizedVariant}
      </Button>
    </div>
  );
};
