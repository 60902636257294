import { useEffect, useState } from 'react';

import { PublicationSetting } from 'data/generated/graphql';
import { getSnippetyData } from 'hooks/useSnippetyQuery';
import { getDomain } from './useMediaForm';

type MediaTypes = 'chart' | 'video' | 'image';

function useControlledQuery(
  type: MediaTypes,
  initialQuery: { id: string; identifier: string } | null,
  authToken: string,
  dispatch: (action: { type: 'UPDATE_CONTENT_ITEM'; payload: any }) => void,
  publicationSetting?: PublicationSetting
): (query: { id: string; identifier: string } | null) => void {
  const [controlledQuery, setControlledQuery] = useState(initialQuery);

  useEffect(() => {
    async function queryData() {
      if (!controlledQuery) return;
      const { identifier, id } = controlledQuery;
      const domain = getDomain(publicationSetting, type, identifier);
      const snippetyData = await getSnippetyData(authToken, domain);
      dispatch({
        type: 'UPDATE_CONTENT_ITEM',
        payload: {
          id,
          fields: type === 'chart' ? { chart: snippetyData?.media } : { videoImage: snippetyData?.media }
        }
      });
    }
    queryData().catch((e) => {
      console.error(`Error fetching snippety data for ${type}`, e);
    });
  }, [controlledQuery, authToken, publicationSetting, dispatch, type]);

  return setControlledQuery;
}

export default useControlledQuery;
