import { useEffect, useMemo, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  IconCode2,
  IconMinusCircle,
  IconThreeDotsVer,
  List,
  Typography,
  Wrapper
} from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { PageModuleItemQuery, PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { addModuleItem, AllContentIds } from 'features/page-edit/components/page-draft-section/PageDraftSectionUtils';
import { useAllessehContentQuery } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { BaseQueryOrigin, getBaseQuery } from 'utils/contentType';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import { QueryModal } from './components/query-modal/QueryModal';
import styles from './QueryModuleItem.module.scss';

interface QueryModuleItemProps {
  availableModuleItemSlots: number | null;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  onChangeQueryItem: ((newQueryItem: PageModuleItemQuery) => void) | null;
  onNumItemsLoaded: (numItems: number) => void;
  onRemove: (() => void) | null;
  queryItem: PageModuleItemQuery;
  getDuplicateClassName?: (id: string) => string;
  setAllContentIds?: React.Dispatch<(state: AllContentIds) => AllContentIds>;
  moduleHierarchyId: string;
  index: number;
  isDraft: boolean;
  treatmentType?: string;
  contentTypes?: PublicationSettingSearchableContentType[];
}

export const QueryModuleItem = ({
  queryItem,
  onRemove,
  availableModuleItemSlots,
  onChangeQueryItem,
  onNumItemsLoaded,
  dragHandleProps,
  moduleHierarchyId,
  index,
  getDuplicateClassName,
  isDraft,
  treatmentType,
  contentTypes
}: QueryModuleItemProps) => {
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { trackContent, getAltSummFields } = useSummarianContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const numItemsToShow = availableModuleItemSlots ?? 0;
  const baseQuery = getBaseQuery({
    contentTypes,
    publicationSettings: publicationSettingsResp?.publicationSetting,
    origin: BaseQueryOrigin.QUERY_BUILDER
  });

  const fullAllessehQuery = mergeAllessehQueryBodies(baseQuery, queryItem.jsonQuery);
  const { setAllContentIds, hasRecentlyPublished, page } = usePagePublish();
  const enabled = !!(numItemsToShow > 0);
  const {
    data,
    isLoading: isLoad,
    fetchStatus
  } = useAllessehContentQuery(fullAllessehQuery, {
    enabled
  });
  const isLoading = isLoad && fetchStatus !== 'idle';
  const initContents = useMemo(
    () =>
      data
        ? (data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x) => !!x) as AllessehContent[])
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, numItemsToShow]
  );

  useEffect(() => {
    initContents.forEach((content) => {
      trackContent(content);
    });
  }, [initContents, trackContent]);

  useEffect(() => {
    onNumItemsLoaded(initContents.length);
  }, [initContents, onNumItemsLoaded]);

  useEffect(() => {
    const parsedQuery = JSON.parse(queryItem.jsonQuery) as AllessehContentQueryBody;
    if (isDraft && parsedQuery.count !== numItemsToShow) {
      const updatedQuery = JSON.stringify({ ...parsedQuery, count: numItemsToShow });
      onChangeQueryItem?.({ jsonQuery: updatedQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initContents, treatmentType]);

  useEffect(() => {
    if (isDraft) {
      setAllContentIds((prevState: AllContentIds) =>
        addModuleItem(
          prevState,
          { moduleHierarchyId, index },
          initContents.map((content) => content.data.id)
        )
      );
    }
  }, [initContents, index, isDraft, moduleHierarchyId, setAllContentIds, hasRecentlyPublished, page?.pageModules]);

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const handleChangeJsonQueryStr = (newJsonQueryStr: string) => {
    setIsModalOpen(false);
    const newQueryItem = { ...queryItem };
    newQueryItem.jsonQuery = newJsonQueryStr;
    if (queryItem.jsonQuery !== newJsonQueryStr) onChangeQueryItem?.(newQueryItem);
  };

  return (
    <>
      <Box className={styles.cardContainer} {...dragHandleProps}>
        <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader}>
          <Typography size="md" weight="bold">
            Query
          </Typography>
          <Dropdown padding={{ all: 'none' }} position="right" trigger={<IconThreeDotsVer color="asphalt" />}>
            {onRemove && (
              <>
                <Wrapper padding={{ all: 'md' }}>
                  <Button tertiary icon={IconMinusCircle as SvgComponent} color="lava" onClick={onRemove}>
                    Remove from module
                  </Button>
                </Wrapper>
                <Divider />
              </>
            )}
            <Wrapper padding={{ all: 'md' }}>
              <Button tertiary icon={IconCode2 as SvgComponent} onClick={handleToggleModal}>
                {onChangeQueryItem ? 'Edit query' : 'View query'}
              </Button>
            </Wrapper>
          </Dropdown>
        </Wrapper>
        {isLoading && <ResponsiveLoader />}
        {!isLoading && (
          <Wrapper padding={{ right: 'sm', left: 'xs', bottom: 'sm' }}>
            {Number(numItemsToShow) <= 0 && <Typography size="sm">No items</Typography>}
            {initContents.length > 0 && (
              <List listStyle="bullet">
                {initContents.map((content, index) => (
                  <List.Item
                    className={getDuplicateClassName?.(content.data.id)}
                    key={index}
                    data-allesseh-id={content.data.id}
                  >
                    <Typography variant="bodytext" className={styles.headline}>
                      {getAltSummFields(content).headline}
                    </Typography>
                  </List.Item>
                ))}
              </List>
            )}
            {availableModuleItemSlots === null && initContents.length > 0 && (
              <Typography variant="note" margin={{ left: 'sm' }}>
                + {(data?.pages[0].links?.total ?? 0) - numItemsToShow} more
              </Typography>
            )}
          </Wrapper>
        )}
      </Box>
      {isModalOpen && (
        <QueryModal
          allessehJsonQuery={queryItem.jsonQuery}
          excludePageContentIdsQuery=""
          onDismiss={handleToggleModal}
          onChangeJsonQueryStr={onChangeQueryItem ? handleChangeJsonQueryStr : null}
          count={numItemsToShow}
          baseQuery={baseQuery}
          moduleHierarchyId={moduleHierarchyId}
          index={index}
          isDraft={isDraft}
        />
      )}
    </>
  );
};
