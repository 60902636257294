import env from 'utils/env';

const ENV_MAP = {
  P: 'prod',
  Q: 'int',
  D: 'dev'
};

const PUB_MAP = {
  WSJC: 'wsjcommerce',
  BAR: 'barrons',
  MKTW: 'mw',
  PENTAB: 'penta',
  MG: 'mansionglobal',
  WSJ: 'wsj',
  FN: 'fn'
};
const WORDPRESS_PROD_MAP = {
  FN: 'https://cms.fnlondon.com/wp-admin/post.php',
  PN: 'https://cms.penews.com/wp-admin/post.php'
};

const WORDPRESS_NONPROD_MAP = {
  FN: 'https://int-qa-fnlondon-wordpress-cms.vir-dev.onservo.com/wp-admin/post.php',
  PN: 'https://int-qa-penews-wordpress-cms.vir-dev.onservo.com/wp-admin/post.php'
};

const getWordpressUrl = (id: string) => {
  const prefix = id.match(/^(PN|FN)/) as Array<string>;
  const postId = id.replace(/^(PN|FN)/, '');
  const baseUrl =
    env.OKTA_ENV === 'prd'
      ? WORDPRESS_PROD_MAP[prefix[0] as keyof typeof WORDPRESS_PROD_MAP]
      : WORDPRESS_NONPROD_MAP[prefix[0] as keyof typeof WORDPRESS_NONPROD_MAP];
  return `${baseUrl}?post=${postId}&action=edit`;
};

const buildArticleNewspressUrl = (id?: string) => {
  if (id?.match(/W[PQD]-/)) {
    const [envPrefix, publicationAbbr, longPostId] = id.split('-');
    const publication = PUB_MAP[publicationAbbr as keyof typeof PUB_MAP];
    const articleEnv = ENV_MAP[envPrefix[1] as keyof typeof ENV_MAP];
    const postId = Number(longPostId);
    if (!publication || !articleEnv || !postId) {
      return null;
    }

    if (articleEnv === ENV_MAP.P && env.OKTA_ENV !== 'prd') {
      return null;
    }

    return `https://newspress${
      articleEnv === ENV_MAP.P ? '' : `.${articleEnv}`
    }.dowjones.io/${publication}/wp-admin/post.php?post=${postId}&action=edit&readonly=true`;
  }

  if (id?.match(/^(PN|FN)/)) return getWordpressUrl(id);

  return null;
};

const NP_PC_ID_PATTERN = {
  BUYSIDE: 'CP-WSJ-'
};

// TODO: make compatible with other publications
const buildProductCardNewspressUrl = (id?: string) => {
  if (id?.match(NP_PC_ID_PATTERN.BUYSIDE)) {
    const pcId = id.replace(NP_PC_ID_PATTERN.BUYSIDE, '');
    return `https://commerce-products.${env.OKTA_ENV === 'prd' ? '' : 'dev.'}dowjones.io/buyside/${pcId}`;
  }
  console.warn('Product card ID is invalid. Please adjust the function to support non-buyside publications.');

  return null;
};

// strip IM- from the narrator id (IM-3d850f07-635c-4b3c-b4b1-8bafd89d7052)
const buildNarratorUrl = (id?: string) => `https://narrator.wsj.dowjones.io/project/${id?.substring(3)}`;

export const buildNewspressUrl = (id?: string, contentType?: string) => {
  if (id?.startsWith('IM-')) return buildNarratorUrl(id);
  switch (contentType) {
    case 'commerceproduct':
      return buildProductCardNewspressUrl(id);
    default:
      return buildArticleNewspressUrl(id);
  }
};

export const buildNewsgridUrl = (id: string) => {
  const [envPrefix, publicationAbbr] = id.split('-');
  const articleEnv = ENV_MAP[envPrefix[1] as keyof typeof ENV_MAP];
  const publication = PUB_MAP[publicationAbbr as keyof typeof PUB_MAP];
  if (!publication || !articleEnv || (env.OKTA_ENV !== 'prd' && articleEnv === ENV_MAP.P)) {
    return null;
  }

  return `https://newsgrid${articleEnv === 'prod' ? '' : `.${articleEnv}`}.dowjones.io/article/${id}`;
};

export const buildLiveCoverageBaseUrl = (sourceUrl: string, source: string) => {
  let articleEnv = 'prod';
  if (sourceUrl.toLowerCase().includes('dev')) {
    articleEnv = 'dev';
  }
  if (sourceUrl.toLowerCase().includes('int')) {
    articleEnv = 'int';
  }
  return `https://livecoverage${articleEnv === 'prod' ? '' : `.${articleEnv}`}.dowjones.io/${source}/news/event/`;
};

export const getBrand = (product: string) => {
  let brand;
  if (product.toLowerCase().includes('wsj')) {
    brand = 'wsj';
  } else if (product.toLowerCase().includes('marketwatch')) {
    brand = 'marketwatch';
  } else if (product.toLowerCase().includes('barrons')) {
    brand = 'barrons';
  } else {
    brand = '';
  }
  return brand;
};

export const buildLiveCoverageUrl = (sourceUrl: string, product: string) => {
  const brand = getBrand(product);
  const baseUrl = buildLiveCoverageBaseUrl(sourceUrl, brand);
  const eventId = sourceUrl.split('/')[sourceUrl.split('/').length - 1];
  return `${baseUrl}${eventId}/settings`;
};
