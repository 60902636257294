import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';
import { Button, Dialog, Typography } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { useAlert } from 'contexts/alert/useAlert';
import { NewsletterProvider } from 'contexts/newsletter/NewsletterContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import {
  AllessehContentType,
  Newsletter,
  NewsletterCreateInput,
  useCancelScheduledContentMutation,
  useCreateNewsletterMutation,
  useGetScheduledContentByAllessehIdQuery,
  useRescheduleContentMutation
} from 'data/generated/graphql';
import { formatNewsletterPageModulesForPublish } from 'features/newsletters/NewsletterEdit';
import { ReadOnlyNewsletter } from 'features/newsletters/ReadOnlyNewsletter';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { formatInTimeZone } from 'utils/dates';
import { getTimeZoneOffsetByName } from 'utils/newsletters';

const ScheduledNewsletterPage = () => {
  const { campaignId, publishUtc: currentPublishUtc } = useParams();
  const currentProperty = useConvertedProperty();

  const { data: publicationSettingsResp } = usePublicationSettings();

  const [showSendNowModal, setShowSendNowModal] = useState(false);

  const { data, isLoading, isError } = useGetScheduledContentByAllessehIdQuery({
    allessehId: campaignId ?? '',
    publishUtc: Number(currentPublishUtc),
    publicationKey: currentProperty ?? ''
  });

  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();

  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  const { mutateAsync: cancelScheduledMutateAsync } = useCancelScheduledContentMutation();

  const { mutateAsync: rescheduleContentMutateAsync } = useRescheduleContentMutation();

  const { mutateAsync: createNewsletterMutateAsync } = useCreateNewsletterMutation();

  const isStillScheduled = Number(currentPublishUtc) > Date.now();

  if (isLoading) return <ResponsiveLoader />;

  if (isError) return <ErrorPage type="500" />;

  // make sure this item belongs to the current property
  if ((data.scheduledContentByAllessehId[0].body as Newsletter).publicationKey !== currentProperty)
    return <ErrorPage type="500" />;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!data || !isStillScheduled || !(campaignId && currentProperty)) {
    return (
      <PageRootContainer>
        <NoSearchResults
          title="Campaign"
          bodyText="You either have the wrong URL or this scheduled campaign has been sent."
        />
      </PageRootContainer>
    );
  }

  const campaign: Newsletter & { status: string } = {
    id: data.scheduledContentByAllessehId[0].allessehId,
    status: data.scheduledContentByAllessehId[0].status,
    createdUtc: data.scheduledContentByAllessehId[0].createdUtc,
    publishUtc: data.scheduledContentByAllessehId[0].publishUtc,
    creatorUser: data.scheduledContentByAllessehId[0].creatorUser,
    publicationKey: currentProperty,
    name: (data.scheduledContentByAllessehId[0].body as Newsletter).name,
    parameters: (data.scheduledContentByAllessehId[0].body as Newsletter).parameters,
    pageModules: (data.scheduledContentByAllessehId[0].body as Newsletter).pageModules
  };

  const handleRescheduleCampaign = async (publishUtc: number) => {
    let adjustedPublishUtc;
    const publishDateObj = new Date(publishUtc);
    const desiredTZOffset = getTimeZoneOffsetByName(
      publicationSettingsResp?.publicationSetting.preferredTimezone.timezone
    );
    if (desiredTZOffset) {
      const currentTZOffset = publishDateObj.getTimezoneOffset();
      const offsetDifference =
        currentTZOffset < 0 ? currentTZOffset - desiredTZOffset : desiredTZOffset - currentTZOffset;
      adjustedPublishUtc = publishUtc + offsetDifference * 60 * 1000;
    }
    const scheduledInput = {
      id: campaignId,
      name: campaign.name,
      publicationKey: currentProperty,
      updatedUtc: null,
      creatorUser: campaign.creatorUser,
      parameters: { ...campaign.parameters },
      pageModules: formatNewsletterPageModulesForPublish(campaign.pageModules, true),
      createdUtc: new Date().getTime()
    };
    try {
      const rescheduleResponse = await rescheduleContentMutateAsync({
        scheduledContentRescheduleInput: {
          allessehId: campaignId,
          previousPublishUtc: Number(currentPublishUtc),
          newPublishUtc: adjustedPublishUtc ?? publishUtc,
          contentType: AllessehContentType.Newsletter,
          body: { ...scheduledInput }
        },
        publicationKey: currentProperty
      });
      navigate(
        `/${currentProperty}/newsletters/scheduled/${rescheduleResponse.rescheduleContent.allessehId}/${rescheduleResponse.rescheduleContent.publishUtc}`
      );
      alertSuccess('Campaign successfully rescheduled.');
    } catch (e: unknown) {
      console.error(e);
      alertError(`Rescheduling campaign failed with the following error: ${e as string}`);
    }
  };

  const toggleSendNowModal = () => setShowSendNowModal(!showSendNowModal);

  const cancelScheduleAndSendNow = async () => {
    // cancel scheduled campaign
    const scheduledContentCancelInput = {
      allessehId: campaignId,
      publishUtc: Number(currentPublishUtc)
    };
    try {
      await cancelScheduledMutateAsync({
        scheduledContentCancelInput,
        publicationKey: currentProperty
      });
    } catch (e: unknown) {
      console.error(e);
      alertError(`Cancel campaign failed with the following error: ${e as string}`);
      toggleSendNowModal();
      return;
    }

    // create new campaign to send now
    const createInput = {
      id: campaignId,
      name: (campaign as Newsletter).name,
      publicationKey: currentProperty,
      parameters: {
        ...(campaign as Newsletter).parameters
      },
      pageModules: formatNewsletterPageModulesForPublish(campaign.pageModules)
    };

    try {
      const publishedNewsletter = await createNewsletterMutateAsync({
        newsletterCreateInput: createInput as NewsletterCreateInput
      });
      navigate(`/${currentProperty}/newsletters/${publishedNewsletter.createNewsletter.id}`);
      alertSuccess(
        `Campaign successfully sent on ${formatInTimeZone(
          publishedNewsletter.createNewsletter.createdUtc,
          publicationSettingsResp?.publicationSetting.preferredTimezone,
          'MMMM dd, yyyy',
          { showTimezoneAbbreviation: false }
        )} at ${formatInTimeZone(
          publishedNewsletter.createNewsletter.createdUtc,
          publicationSettingsResp?.publicationSetting.preferredTimezone,
          'h:mm aaaa',
          { showTimezoneAbbreviation: true }
        )}.`
      );
    } catch (e: unknown) {
      console.error(e);
      alertError(`Campaign failed with the following error: ${e as string}`);
    }
  };

  const handleCancelCampaign = async () => {
    const scheduledContentCancelInput = {
      allessehId: campaignId,
      publishUtc: Number(currentPublishUtc)
    };
    try {
      await cancelScheduledMutateAsync({
        scheduledContentCancelInput,
        publicationKey: currentProperty
      });
      navigate(`/${currentProperty}/newsletters`);
      alertSuccess('Campaign successfully canceled.');
    } catch (e: unknown) {
      console.error(e);
      alertError(`Cancel campaign failed with the following error: ${e as string}`);
    }
  };

  return (
    <PageRootContainer>
      <SummarianProvider>
        <NewsletterProvider currentProperty={currentProperty}>
          <ReadOnlyNewsletter
            campaign={campaign}
            cancelCampaign={() => setCancelModalOpen(true)}
            rescheduleCampaign={handleRescheduleCampaign}
            handleSendNowClick={toggleSendNowModal}
          />
          {showSendNowModal && (
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            <Dialog status={showSendNowModal ? 'open' : 'closed'} onDismiss={toggleSendNowModal}>
              <Dialog.Title id="dialogTitle">Send Now</Dialog.Title>
              <Dialog.Content>
                <Typography>
                  Are you sure you want to send this campaign immediately rather than on{' '}
                  {publicationSettingsResp && campaign.publishUtc
                    ? formatInTimeZone(
                        campaign.publishUtc,
                        publicationSettingsResp.publicationSetting.preferredTimezone,
                        'MMMM dd, yyyy: hh:mm aaaa',
                        { showTimezoneAbbreviation: true }
                      )
                    : ''}
                  ?
                </Typography>
              </Dialog.Content>
              <Dialog.Actions>
                <Button secondary onClick={toggleSendNowModal}>
                  Cancel
                </Button>
                <Button primary onClick={cancelScheduleAndSendNow}>
                  Send Now
                </Button>
              </Dialog.Actions>
            </Dialog>
          )}
          {cancelModalOpen && (
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            <Dialog status={cancelModalOpen ? 'open' : 'closed'} onDismiss={() => setCancelModalOpen(false)}>
              <Dialog.Title id="dialogTitle">Cancel Campaign</Dialog.Title>
              <Dialog.Content id="dialogDesc">
                <Typography>Are you sure you want to cancel this campaign?</Typography>
              </Dialog.Content>
              <Dialog.Actions>
                <Button secondary onClick={() => setCancelModalOpen(false)}>
                  Keep Campaign
                </Button>
                <Button primary onClick={handleCancelCampaign}>
                  Cancel Campaign
                </Button>
              </Dialog.Actions>
            </Dialog>
          )}
        </NewsletterProvider>
      </SummarianProvider>
    </PageRootContainer>
  );
};

export default ScheduledNewsletterPage;
