import { useEffect, useRef } from 'react';
import { Button, FormHelperText, FormLabel, Group, Input, Wrapper } from '@screentone/core';

import { SnippetyResponseWithImage, VideoDataForSummarian } from 'hooks/useSnippetyQuery';
import styles from '../MediaInput.module.scss';
import { MediaTypes } from '../MediaInput.utils';

type Props = {
  onIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  id: string;
  error: string;
  onIdAdd:
    | ((imageData: SnippetyResponseWithImage | null) => void)
    | ((videoData: VideoDataForSummarian | null) => void);
  isIdFormOpen: boolean;
  setIsIdFormOpen: (isOpen: boolean) => void;
  shouldAdd: boolean;
  inputType: MediaTypes | '';
  setVariant: (variant: MediaTypes | '') => void;
};

export const MediaInputForm = ({
  onIdChange,
  onDelete,
  id,
  error,
  onIdAdd,
  isIdFormOpen,
  setIsIdFormOpen,
  shouldAdd,
  setVariant,
  inputType
}: Props) => {
  const onCancel = () => {
    setIsIdFormOpen(false);
    setVariant('');
    onDelete();
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isIdFormOpen && ref.current) {
      ref.current.focus();
    }
  }, [isIdFormOpen]);

  function getLabel() {
    switch (inputType) {
      case 'image':
        return { formLabel: 'Image ID', inputPlaceholder: 'Image ID' };
      case 'video':
        return { formLabel: 'Video GUID', inputPlaceholder: 'Video GUID' };
      case 'chart':
        return { formLabel: 'Chart ID', inputPlaceholder: 'Chart ID' };
      default:
        return { formLabel: 'Media ID', inputPlaceholder: 'Media ID' };
    }
  }

  return (
    <div data-testid="media-input-form-wrapper">
      {isIdFormOpen && (
        <Wrapper data-testid="media-input-form" padding={{ top: 'lg', right: 'sm', bottom: 'lg', left: 'sm' }}>
          <Group data-testid="media-input-form-container" fullWidth valign="end" gap="xs" wrap={false}>
            <FormLabel data-testid="media-input-form-id" label={getLabel().formLabel} fullWidth>
              <Input
                data-testid="media-input-form-media-input"
                type="text"
                placeholder={getLabel().inputPlaceholder}
                onChange={onIdChange}
                value={id}
                error={!!error}
                componentRef={ref}
              />
            </FormLabel>
            <Button
              data-testid="media-input-form-add-button"
              primary
              onClick={onIdAdd}
              disabled={!shouldAdd}
              margin={{ left: 'xs' }}
              className={styles.addImageButton}
            >
              Add
            </Button>
          </Group>
          {error && (
            <FormHelperText data-testid="media-input-form-error" error>
              {error}
            </FormHelperText>
          )}
          <Group valign="end" gap="xs" wrap={false} margin={{ top: 'sm' }}>
            <Button
              tertiary
              // icon={IconTrash as SvgComponent}
              color="lava"
              onClick={onCancel}
            >
              Hide
            </Button>
          </Group>
        </Wrapper>
      )}
    </div>
  );
};
