import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { IssueError } from 'contexts/alert/AlertContext';
import { useAlert } from 'contexts/alert/useAlert';
import { AltSummVariant } from 'contexts/summarian/SummarianContext';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import {
  ItpIssueStatus,
  ItpIssueWithPages,
  ItpPage,
  PageType,
  PageTypeSetting,
  useDeleteItpMutation,
  useItpByDateQuery,
  usePageTypeSettingByPublicationKeyPageTypeQuery,
  usePreviewItpLinkMutation,
  usePublishItpMutation,
  useSaveItpMutation
} from 'data/generated/graphql';
import { useHighlight } from 'features/issues/hooks/useNewHighlight';
import { DISPLAY_KEY } from 'features/issues/util';
import { prepPageModulesForPublish } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { clearValidationError, mergeModulesWithValidationError } from 'features/page-edit/pageEditUtils';
import { safelyParseContent } from 'utils/temp';

const noop = async () => {
  // do nothing
};

interface IssueContext {
  error: unknown;
  issue: ItpIssueWithPages | null;
  itpAltSummUpdated: boolean;
  previewIds: string[];
  staleIds: string[];
  handlePartialIssueChange: (issue: Partial<ItpIssueWithPages>, updateUtc?: boolean) => void;
  clearErrorState: () => void;
  saveIssue: () => Promise<void>;
  deleteIssue: () => Promise<void>;
  publishIssue: () => Promise<void>;
  handlePreviewPage: () => void;
  refreshedUtc: number | null;
  isLoading: boolean;
  setError: (error: unknown) => void;
  setRefreshedUtc: (date: number | null) => void;
  pageTypeSetting: PageTypeSetting | undefined;
  isSaveLoading: boolean;
  isDeleteLoading: boolean;
  isPublishLoading: boolean;
  isPreviewLoading: boolean;
  hasITPPageChanged: boolean;
  setPreviewIds: (ids: string[]) => void;
  setStaleIds: (ids: string[]) => void;
  setItpAltSummUpdated: (updated: boolean) => void;
}

const DEFAULT_ISSUE_STATE: IssueContext = {
  error: null,
  issue: null,
  itpAltSummUpdated: false,
  previewIds: [],
  staleIds: [],
  handlePartialIssueChange: noop,
  clearErrorState: noop,
  saveIssue: noop,
  deleteIssue: noop,
  publishIssue: noop,
  handlePreviewPage: noop,
  isLoading: true,
  refreshedUtc: null,
  setError: noop,
  setRefreshedUtc: noop,
  pageTypeSetting: undefined,
  isSaveLoading: false,
  isDeleteLoading: false,
  isPublishLoading: false,
  isPreviewLoading: false,
  hasITPPageChanged: false,
  setPreviewIds: noop,
  setStaleIds: noop,
  setItpAltSummUpdated: noop
};

export const IssueContext = createContext<IssueContext>(DEFAULT_ISSUE_STATE);

const formatRequestBody = (issue: ItpIssueWithPages, refreshedUtc: number | null, currentProperty: string | null) => ({
  itpSaveInput: {
    issueId: issue.issueId,
    issueDate: issue.issueDate,
    label: issue.label,
    refreshedUtc,
    pages: issue.pages.map((page) => ({
      adType: page.adType,
      adZones: page.adZones,
      isHidden: page.isHidden,
      issueId: page.issueId,
      label: page.label,
      pageModules: prepPageModulesForPublish(page.pageModules),
      pageType: page.pageType
    }))
  },
  publicationKey: currentProperty ?? 'wsj'
});

interface IssueProviderProps {
  children: React.ReactNode;
  issueDate: string;
  property: string;
}

const sortPagesByOrder = (pages: ItpPage[]) =>
  [...pages].sort((a, b) => {
    const aPageObject = DISPLAY_KEY[a.pageType];
    const bPageObject = DISPLAY_KEY[b.pageType];

    return aPageObject.order - bPageObject.order;
  });

const sortIdsByOrder = (ids: string[]) => {
  const sectionNameRegex = /UCS(D|P)_[A-Z_]+_ITP_ITP\d{8}_(.*?)_$/;

  const defaultOrder = 100;
  return ids.sort((id1, id2) => {
    const sectionName1 = (id1.match(sectionNameRegex) ?? [])[2];
    const sectionName2 = (id2.match(sectionNameRegex) ?? [])[2];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const order1 = DISPLAY_KEY[sectionName1] ? DISPLAY_KEY[sectionName1].order : defaultOrder;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const order2 = DISPLAY_KEY[sectionName2] ? DISPLAY_KEY[sectionName2].order : defaultOrder;
    return order1 - order2;
  });
};

export const IssueProvider = ({ children, issueDate, property }: IssueProviderProps) => {
  const [error, setError] = useState<unknown>(null);
  const [issue, setIssue] = useState<ItpIssueWithPages | null>(null);
  const [refreshedUtc, setRefreshedUtc] = useState<number | null>(null);
  const [hasITPPageChanged, setHasITPPageChanged] = useState(false); // used to disable/enable publishing buttons
  const { currentProperty, user } = useAuth();
  const { setSelectedAltSummVariant } = useSummarianContext();
  const { alertSuccess, alertError, removeAlert } = useAlert();
  const { clearItems } = useHighlight();
  const navigate = useNavigate();
  const [previewIds, setPreviewIds] = useState<string[]>([]);
  const [staleIds, setStaleIds] = useState<string[]>([]);
  const [itpAltSummUpdated, setItpAltSummUpdated] = useState(false);

  const updateIssue = useCallback((issueToModify: ItpIssueWithPages) => {
    setIssue(issueToModify);
  }, []);

  const {
    isLoading,
    data,
    error: queryError,
    fetchStatus
  } = useItpByDateQuery({
    issueDate,
    publicationKey: property
  });

  const { data: pageTypeSettingsResp } = usePageTypeSettingByPublicationKeyPageTypeQuery(
    { publicationKey: property, pageType: PageType.Itp },
    { enabled: !!property }
  );

  const pageTypeSetting = pageTypeSettingsResp?.pageTypeSettingByPublicationKeyPageType as PageTypeSetting | undefined;

  const { mutateAsync: saveItpAsync, isLoading: isSaveLoading } = useSaveItpMutation();
  const { mutateAsync: deleteItpAsync, isLoading: isDeleteLoading } = useDeleteItpMutation();
  const { mutateAsync: publishItpAsync, isLoading: isPublishLoading } = usePublishItpMutation();
  const { mutateAsync: previewItpAsync, isLoading: isPreviewLoading } = usePreviewItpLinkMutation();

  useEffect(() => {
    if (!isLoading && fetchStatus === 'idle') {
      if (queryError || !data?.itpByDate) {
        setError(queryError ?? '500 - Failed to fetch issue.');
      } else {
        const itpIssueByDate = data.itpByDate;

        itpIssueByDate.pages = sortPagesByOrder(itpIssueByDate.pages);

        setIssue(itpIssueByDate);
        updateIssue(itpIssueByDate);
        setRefreshedUtc(itpIssueByDate.refreshedUtc ?? null);
        setSelectedAltSummVariant(itpIssueByDate.summaryPriority as AltSummVariant);
      }
    }
  }, [isLoading, fetchStatus, data, queryError, updateIssue, setSelectedAltSummVariant]);

  const handlePartialIssueChange = (partialIssue: Partial<ItpIssueWithPages>, updateUtc?: boolean) => {
    // if we are trying to change an issue we could not fetch, something went wrong
    if (!issue) {
      setError('500 - Failed to retrieve issue - cannot perform update.');
      return;
    }
    const newIssue: ItpIssueWithPages = {
      ...issue,
      label: partialIssue.label ?? issue.label,
      pages: sortPagesByOrder(partialIssue.pages ?? issue.pages),
      publishUtc: partialIssue.publishUtc ?? issue.publishUtc,
      status: partialIssue.status ?? issue.status
    };
    if (updateUtc) {
      newIssue.updatedUtc = Date.now();
      newIssue.revisorUser = partialIssue.revisorUser ?? issue.revisorUser;
    }

    updateIssue(newIssue);
    setHasITPPageChanged(true);
  };

  const clearValidationErrors = () => {
    handlePartialIssueChange({
      pages: issue?.pages.map((page) => ({
        ...page,
        pageModules: clearValidationError(page.pageModules)
      }))
    });
  };

  const clearErrorState = () => {
    removeAlert();
    clearValidationErrors();
  };

  const saveOrPublishIssue = async (action: typeof saveItpAsync | typeof publishItpAsync) => {
    if (!issue) {
      return;
    }

    // Clear highlighted items.
    clearItems();
    clearErrorState();

    const requestBody = formatRequestBody(issue, refreshedUtc, currentProperty);

    try {
      const response = await action(requestBody);
      const isSaveAction = 'saveITP' in response;
      const itpResponse = isSaveAction ? response.saveITP : response.publishITP;

      const mergedResponse = {
        ...itpResponse,
        // We merge the page modules we already have in our issue with the response we get from the mutation
        // Additionally, we set the `hasValidationError` flag to false for all modules.
        pages: itpResponse.pages.map((page) => ({
          ...page,
          pageModules: issue.pages
            .find((issuePage) => page.pageType === issuePage.pageType)!
            .pageModules.map((pageModule) => ({ ...pageModule, hasValidationError: false }))
        })),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        revisorUser: { djUserId: user?.dj_user_id }
      };

      handlePartialIssueChange(mergedResponse, true);

      if (isSaveAction) {
        alertSuccess('Draft successfully saved');
      } else {
        alertSuccess('Issue successfully published');
      }
      setHasITPPageChanged(false);
    } catch (e) {
      const error = e as Error;
      let errorMessage = '';

      // Attempt to read error message as JSON.
      // If it is JSON, it's a validation error.
      const errorJson = safelyParseContent<IssueError, string>(error.message, error.message);
      if (typeof errorJson === 'string') {
        errorMessage = errorJson;
      } else {
        errorMessage = errorJson.message;

        // Merge the modules with validation errors from the response with the modules we already have in our issue
        let idx = 0;
        const pages = issue.pages.map((page) => {
          if (page.isHidden) {
            // Hidden pages are not validated, so we return them as-is.
            return page;
          }

          const currentPageError = errorJson.validationError!.pageErrors[idx];
          const modulesWithValidationError = mergeModulesWithValidationError(
            page.pageModules,
            currentPageError.modulesWithValidationError
          );

          idx++;

          return { ...page, pageModules: modulesWithValidationError };
        });

        handlePartialIssueChange({
          ...issue,
          pages
        });
      }

      alertError(errorMessage, { wrapNewlines: true });
      setError(error);
    }
  };

  const saveIssue = async () => {
    await saveOrPublishIssue(saveItpAsync);
  };

  const deleteIssue = async () => {
    const deleteResponse = await deleteItpAsync({
      publicationKey: currentProperty ?? 'wsj',
      issueId: issue?.issueId ?? ''
    });
    if (deleteResponse.deleteITP.status === ItpIssueStatus.Deleted) {
      navigate(`/${currentProperty}/issues`);
      alertSuccess('Issue deleted successfully');
    } else {
      throw new Error('Failed to delete issue');
    }
  };

  const publishIssue = async () => {
    await saveOrPublishIssue(publishItpAsync);
    setItpAltSummUpdated(false);
  };

  const handlePreviewPage = () => {
    if (issue) {
      const requestBody = formatRequestBody(issue, refreshedUtc, currentProperty);
      previewItpAsync(requestBody)
        .then((response) => {
          if (response.previewItpLink) {
            const sortedIds = sortIdsByOrder(response.previewItpLink);
            setPreviewIds(sortedIds);
            setHasITPPageChanged(false);
            handlePartialIssueChange(
              {
                ...issue,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                revisorUser: { djUserId: user?.dj_user_id }
              },
              true
            );
          } else {
            alertError('Could not set up previewing for the page.');
          }
        })
        .catch((e: unknown) => {
          console.error('Error previewing', e);
          if (e instanceof Error) {
            const error = e;
            let errorMessage = '';
            setPreviewIds([]);
            // Attempt to read error message as JSON.
            // If it is JSON, it's a validation error.
            const errorJson = safelyParseContent<IssueError, string>(error.message, error.message);
            if (typeof errorJson === 'string') {
              errorMessage = errorJson;
            } else {
              errorMessage = errorJson.message;

              // Merge the modules with validation errors from the response with the modules we already have in our issue
              let idx = 0;
              const pages = issue.pages.map((page) => {
                if (page.isHidden) {
                  // Hidden pages are not validated, so we return them as-is.
                  return page;
                }

                const currentPageError = errorJson.validationError!.pageErrors[idx];
                const modulesWithValidationError = mergeModulesWithValidationError(
                  page.pageModules,
                  currentPageError.modulesWithValidationError
                );

                idx++;

                return { ...page, pageModules: modulesWithValidationError };
              });

              handlePartialIssueChange({
                ...issue,
                pages
              });
            }

            alertError(errorMessage, { wrapNewlines: true });
            setError(error);
          } else {
            alertError('Could not set up previewing for the page.');
          }
        });
    }
  };

  const value = {
    error,
    issue,
    previewIds,
    staleIds,
    handlePartialIssueChange,
    clearErrorState,
    saveIssue,
    deleteIssue,
    publishIssue,
    handlePreviewPage,
    isLoading: isLoading && fetchStatus !== 'idle',
    refreshedUtc,
    setError,
    setRefreshedUtc,
    pageTypeSetting,
    isSaveLoading,
    isDeleteLoading,
    isPublishLoading,
    isPreviewLoading,
    hasITPPageChanged,
    setPreviewIds,
    setStaleIds,
    itpAltSummUpdated,
    setItpAltSummUpdated
  };

  return <IssueContext.Provider value={value}>{children}</IssueContext.Provider>;
};
