import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';

export interface SnippetySoftCropsData {
  height: number;
  width: number;
  label: string;
  location: string;
}

export interface SnippetyImageData {
  id: string;
  credit: string;
  caption: string;
  title: string;
  aspectRatio: number;
  reuseType: string;
  type: string;
  href?: string;
  src?: {
    baseUrl?: string;
    imageId?: string;
    path?: string;
    prams?: {
      size: number;
    };
  };
  sizeCode: string;
  width: number;
  height: number;
  imageDetailsHref: string;
  contentType: string;
  isResizeOnly: boolean;
  alt_sizes: {
    softcrops: SnippetySoftCropsData[];
  };
  status: string;
  'Content-type': string;
  publication: string;
  url: string;
  application: string;
}

export interface SnippetyChartData {
  media?: string;
  application: string;
  embed: string;
  hed: string;
  id: string;
  imageRatio: number;
  minHeightPerWidth: object;
  published: string;
  title: string;
  url: string;
  status: string;
}

export interface SnippetyToolsDataImage {
  image: SnippetyImageData;
}

export interface SnippetyToolsDataChart {
  chart: SnippetyChartData;
}

export interface SnippetyResponse {
  title: string;
  desc: string;
  provider?: string;
  url: string;
  type: string;
  media: string;
  publication: string;
  id: string;
  [key: string]: any;
}

export interface SnippetyResponseWithImage extends SnippetyResponse {
  tools: SnippetyToolsDataImage;
}

export interface SnippetyResponseWithChart extends SnippetyResponse {
  media: string;
  tools: SnippetyToolsDataChart;
}

export interface SnippetyResponseWithVideo extends SnippetyResponse {
  media: string;
}

export interface SnippetyVideoData extends SnippetyResponse {
  author: string;
  sizes?: {
    full: {
      height: number;
      orientation: 'landscape' | 'portrait'; // Same as orientation
      url: string;
      width: number;
    };
  };
}

export interface VideoDataForSummarian {
  alt: string;
  aspectRatio: number;
  author: string;
  authorName: string;
  caption: string;
  compat: {
    item: string;
    meta: string;
  };
  context: string;
  date: number; // Unix timestamp in seconds
  dateFormatted: string;
  dateModifiedFormatted: string;
  describe: boolean;
  description: string;
  editLink: string;
  filename: string;
  filesizeHumanReadable: string;
  filesizeInBytes: number;
  format: string;
  guid: string;
  height: number;
  icon: string;
  menuOrder: number;
  meta: boolean;
  mime: string;
  modified: number; // Unix timestamp in seconds
  name: string;
  nonces: {
    delete: string;
    edit: string;
    update: string;
  };
  orientation: 'landscape' | 'portrait'; // Assume it can only be landscape or portrait
  search: string;
  sizes?: {
    full: {
      height: number;
      orientation: 'landscape' | 'portrait'; // Same as orientation
      url: string;
      width: number;
    };
  };
  status: string;
  subtype: string;
  title: string;
  type: string;
  uploadedTo: number;
  uploadedToLink: string;
  uploadedToTitle: string;
  url: string;
  width: number;
}

export const getSnippetyData = (authToken: string, url: string) => {
  if (url === '') {
    return Promise.resolve(null);
  }
  return restClient
    .get<SnippetyResponse | SnippetyResponseWithImage | SnippetyResponseWithChart | SnippetyVideoData>(
      `snippety?url=${encodeURIComponent(url)}`,
      {
        headers: { Authorization: authToken }
      }
    )
    .catch((e) => {
      console.error('Error fetching snippety data', e);
    });
};

export const useSnippetyQuery = (url: string, options?: { enabled: boolean }) => {
  const authToken = useAuthToken();
  return useAppQuery([url], () => getSnippetyData(authToken, url), options);
};
