import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, IconCopy, Token, Typography, Wrapper } from '@screentone/core';

import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { PageDto, PagesDtoQuery } from 'data/generated/graphql';
import { OKTA_ENV } from 'features/auth/components/OktaRouter';
import { usePublicationSettings } from 'hooks/publication-settings';
import { useIsAdmin } from 'hooks/roles';
import env from 'utils/env';
import styles from './PagesList.module.scss';

// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
const prod = env.OKTA_ENV === OKTA_ENV.PRD;

const convertRouteKey = (routeKey: string, publicationKey: string): string => {
  if (publicationKey === 'barrons') {
    if (routeKey === '/') return '/';
    if (routeKey.toLowerCase() === '/advisor' && prod) return '/';
    const isAdvisorDev = routeKey.toLowerCase().includes('advisor');
    return `/?curationPage=${routeKey.toLowerCase().replace('/', '')}${isAdvisorDev ? '&ap=y' : ''}`;
  }
  return routeKey.toLowerCase();
};

export const PagesList = ({ pagePages }: { pagePages: PagesDtoQuery[] }) => {
  const { currentProperty } = useAuth();
  const isAdmin = useIsAdmin();
  const { data: publicationSettings } = usePublicationSettings();
  const [pages, setPages] = useState<PageDto[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const pages: PageDto[] = pagePages.reduce((acc: PageDto[], curr: PagesDtoQuery) => {
      const pages = curr.pagesDTO.edges.map((pageEdge) => {
        const { node: page } = pageEdge;
        return page;
      });
      return acc.concat(pages as PageDto[]);
    }, []);

    pages.sort((a, b) => b.metadata.name.localeCompare(a.metadata.name));
    setPages(pages);
  }, [isAdmin, pagePages]);

  if (pagePages.length === 0 || pagePages[0].pagesDTO.edges.length === 0) {
    return <NoSearchResults title="Pages" bodyText="Try a different filter or ask an admin to create a new page." />;
  }

  const handlePageClick = (pageAllessehId: string) => () => {
    const slugMappings = publicationSettings?.publicationSetting.slugMappings;
    const [slug] = Object.entries(slugMappings ?? {}).find(([, allessehId]) => allessehId === pageAllessehId) ?? [];
    const pageId = slug ?? pageAllessehId;

    navigate(`/${currentProperty ?? ''}/pages/${pageId}`);
  };

  return (
    <div data-testid="pages-sections-list">
      {pages.map((page) => {
        const routeKey = convertRouteKey(page.root.attributes.routeKey, page.metadata.publicationKey);
        return (
          <Box
            key={page.metadata.allessehId}
            padding={{ all: 'sm' }}
            margin={{ top: 'sm', bottom: 'sm' }}
            onClick={handlePageClick(page.metadata.allessehId)}
            className={styles.pageItem}
          >
            <Wrapper className={styles.pageItemContent}>
              <div className={styles.pageItemLeft}>
                <Typography variant="bodytext" margin={{ right: 'sm' }} data-testid="pages-names-section">
                  {page.metadata.name}
                </Typography>
                <Typography margin={{ left: 'sm' }} variant="note">
                  {publicationSettings?.publicationSetting.baseRoute}
                  {routeKey}
                  {!prod && (
                    <Button
                      margin={{ left: 'sm' }}
                      tertiary
                      icon={IconCopy as SvgComponent}
                      onClick={async (event: Event) => {
                        event.stopPropagation();
                        return navigator.clipboard.writeText(
                          `${publicationSettings?.publicationSetting.baseRoute}${routeKey}`
                        );
                      }}
                    />
                  )}
                </Typography>
              </div>
              <Token color="gray">{page.root.attributes.pageType}</Token>
            </Wrapper>
          </Box>
        );
      })}
    </div>
  );
};
