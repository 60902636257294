import { createContext, useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import useControlledQuery from 'hooks/altsumm/useControlledQuery';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useAuthToken } from 'hooks/useAuthToken';
import { SnippetyImageData, VideoDataForSummarian } from 'hooks/useSnippetyQuery';
import {
  createVideoUrl,
  getDefaultBody,
  getDefaultBullets,
  getDefaultChartId,
  getDefaultChartMedia,
  getDefaultHeadline,
  getDefaultIframeImage,
  getDefaultImage,
  getDefaultSubhead,
  getDefaultVideo,
  getDefaultVideoGuid
} from './SummarianContext.utils';
import { AltSummFields, AltSummVariant, summarianReducer } from './summarianReducer';

import { getUrlToShowType } from 'components/altsumm-modal/AltSummModal.utils';

const noop = async () => {
  /* do nothing */
};

export type { AltSummVariant };

export const DEFAULT_ALT_SUM_ID = 'U.S. Home';

interface SummarianContext {
  selectedAltSummVariant: AltSummVariant;
  setSelectedAltSummVariant: (selectedAltSummVariant: AltSummVariant) => void;
  trackContent: (content: AllessehContent) => void;
  getAltSummFields: (
    content: AllessehContent,
    altSummVariant?: AltSummVariant
  ) => {
    headline: string;
    body: string;
    image: string;
    video: string;
    videoImage?: string;
    bullets: string[];
    subhead: string;
    chart: string;
    chartlosId: string;
    proxy: SummarianProxy | undefined;
  };
  setAltSummFields: (contentId: AllessehContent, fields: Omit<AltSummFields, 'proxy'>) => void;
  eventTarget: EventTarget;
}

export interface SummarianChangeEvent {
  data: unknown;
  path: string[];
  op: {
    ld?: string;
    p?: any[];
    si?: string;
    oi?: string[];
  };
}

const supportedProps = ['headline', 'body', 'images', 'bullets', 'subhead', 'chartlosId', 'videos', 'chart'] as const;
type SupportedProps = typeof supportedProps[number];
const supportedPropsSet = new Set<string>(supportedProps);

const DEFAULT_SUMMARIAN_STATE: SummarianContext = {
  selectedAltSummVariant: 'U.S. Home',
  setSelectedAltSummVariant: noop,
  trackContent: noop,
  // @ts-expect-error - This is a dummy function, no need to match the signature
  getAltSummFields: noop,
  eventTarget: new EventTarget()
};

interface SummarianProviderProps {
  children: React.ReactNode;
}

export const SummarianContext = createContext(DEFAULT_SUMMARIAN_STATE);

export const SummarianProvider = ({ children }: SummarianProviderProps) => {
  const authToken = useAuthToken();
  const [isSummarianSettled, setIsSummarianSettled] = useState(window.hasSummarianSettled);
  const { data: publicationSettingsResp } = usePublicationSettings();
  const priorityAltSum =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (publicationSettingsResp?.publicationSetting.defaultAltSumm as AltSummVariant) ?? DEFAULT_ALT_SUM_ID;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [state, dispatch] = useReducer(summarianReducer, {
    currentAltSummVariant: priorityAltSum,
    altSummCache: {
      'U.S. Home': {},
      'Home-Page': {},
      ITP: {},
      NewsPlus: {},
      APIImage: {},
      Mobile: {},
      SEO: {},
      'Social Open Graph': {}
    },
    trackedItems: new Map<string, AllessehContent>()
  });
  const eventTarget = useRef(new EventTarget());
  const setChartControlledQuery = useControlledQuery(
    'chart',
    { identifier: '', id: '' },
    authToken,
    dispatch,
    publicationSettingsResp?.publicationSetting
  );

  const setGuidControlledQuery = useControlledQuery(
    'video',
    { identifier: '', id: '' },
    authToken,
    dispatch,
    publicationSettingsResp?.publicationSetting
  );

  useEffect(() => {
    const onSummarianSettled = () => {
      setIsSummarianSettled(true);
    };

    window.summarianSettledEventTarget.addEventListener('summarianSettled', onSummarianSettled);

    return () => {
      window.summarianSettledEventTarget.removeEventListener('summarianSettled', onSummarianSettled);
    };
  }, []);

  useEffect(() => {
    if (!window.isSummarianReady || state.trackedItems.size === 0) return;

    const trackNewItems = async () => {
      const promises = [...state.trackedItems.entries()].map(async ([contentId, content]) => {
        const hasAddedContentAlready = !!state.altSummCache[state.currentAltSummVariant]?.[contentId];
        if (hasAddedContentAlready) return;

        const { currentAltSummVariant } = state;

        // Use cached proxy if available
        let altSumProxy = state.altSummCache[currentAltSummVariant]?.[contentId]?.proxy;
        if (!altSumProxy) {
          altSumProxy = await window.summarianShareDbAPI.proxy(contentId, currentAltSummVariant);
        }

        const headline = (await altSumProxy.headline) ?? getDefaultHeadline(content, priorityAltSum);
        const body = (await altSumProxy.body) ?? getDefaultBody(content, priorityAltSum);
        const imagesFromProxy = await altSumProxy.images;
        const image = imagesFromProxy?.[0]?.href ?? getDefaultImage(content, priorityAltSum);
        const videosFromProxy = await altSumProxy.videos;
        const video = videosFromProxy?.[0]?.guid ?? getDefaultVideoGuid(content, priorityAltSum);
        const videoImage = videosFromProxy?.[0]?.sizes?.full.url;
        const bulletsFromProxy = await altSumProxy.bullets;
        const bullets = bulletsFromProxy ?? [];
        const subhead = (await altSumProxy.subhead) ?? '';
        const chartlosId = (await altSumProxy.chartlosId) ?? getDefaultChartId(content, priorityAltSum);
        const chart = (await altSumProxy.chart) ?? getDefaultChartMedia(content, priorityAltSum);

        if (!videoImage && getDefaultVideoGuid(content, priorityAltSum)) {
          setGuidControlledQuery({ identifier: getDefaultVideoGuid(content, priorityAltSum), id: contentId });
        }

        if (chartlosId) {
          const newChartlosId = chartlosId.includes('cdc_') ? chartlosId.replace('cdc_', '') : chartlosId;
          setChartControlledQuery({ identifier: newChartlosId, id: contentId });
        }

        // eslint-disable-next-line no-underscore-dangle
        altSumProxy.__proxy__.on('change', ({ path, data, op }: SummarianChangeEvent) => {
          if (!window.isSummarianReady) return;

          const prop = path[0];
          if (supportedPropsSet.has(prop)) {
            const supportedProp = prop as SupportedProps;

            if (supportedProp === 'images') {
              const imageData = data as SnippetyImageData | SnippetyImageData[] | undefined;
              const imageHref = Array.isArray(imageData) ? imageData[0]?.href : imageData?.href;
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: {
                  id: contentId,
                  fields: { image: imageHref ?? getDefaultImage(content, priorityAltSum) }
                }
              });
            } else if (supportedProp === 'videos') {
              const videoData = data as VideoDataForSummarian | [VideoDataForSummarian] | undefined | {};
              const videoDataItem = Array.isArray(videoData)
                ? (videoData[0] as VideoDataForSummarian)
                : videoData && typeof videoData === 'object' && 'guid' in videoData
                ? videoData
                : undefined;
              const newVideoImage = videoDataItem?.sizes!.full.url ?? videoImage;
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: {
                  id: contentId,
                  fields: {
                    video:
                      videoDataItem?.guid &&
                      createVideoUrl(videoDataItem.guid, publicationSettingsResp?.publicationSetting),
                    videoImage: videoDataItem ? newVideoImage : videoDataItem
                  }
                }
              });
            } else if (supportedProp === 'bullets') {
              let updatedBullets = [...bullets];
              if (op.oi) {
                updatedBullets = data as string[];
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: { id: contentId, fields: { bullets: [...updatedBullets] } }
                });
              } else if (altSumProxy?.bullets) {
                updatedBullets = Array.isArray(altSumProxy.bullets) ? [...altSumProxy.bullets] : [];
              }
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: { id: contentId, fields: { bullets: [...updatedBullets] } }
              });
            } else if (supportedProp === 'chartlosId') {
              const chartId = data as string;
              if (chartId.length > 0) {
                const newChartlosId = chartlosId.includes('cdc_') ? chartlosId.replace('cdc_', '') : chartlosId;
                setChartControlledQuery({ identifier: newChartlosId, id: contentId });
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: { id: contentId, fields: { chartlosId: data as string } }
                });
              } else {
                setChartControlledQuery(null);
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: { id: contentId, fields: { chartlosId: data as string, chart: '' } }
                });
              }
            } else {
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: { id: contentId, fields: { [supportedProp]: data as string } }
              });
            }

            eventTarget.current.dispatchEvent(new CustomEvent('itemUpdated', { detail: contentId }));
          }
        });

        dispatch({
          type: 'UPDATE_CONTENT_ITEM',
          payload: {
            id: contentId,
            proxy: altSumProxy,
            fields: {
              headline,
              body,
              image,
              video,
              videoImage,
              bullets,
              chartlosId,
              subhead,
              chart,
              iframe: getDefaultIframeImage(content, priorityAltSum)
            }
          }
        });
      });

      await Promise.all(promises);
    };

    void trackNewItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trackedItems, state.currentAltSummVariant]);

  const setSelectedAltSummVariant = useCallback((selectedAltSummVariant: AltSummVariant) => {
    dispatch({ type: 'SET_CURRENT_ALT_SUMM_VARIANT', payload: selectedAltSummVariant });
  }, []);

  const trackContent = useCallback(
    (content: AllessehContent) => {
      const { altSummCache, currentAltSummVariant, trackedItems } = state;
      if (altSummCache[currentAltSummVariant]?.[content.data.id] || trackedItems.has(content.data.id)) return;

      dispatch({ type: 'TRACK_CONTENT_ITEM', payload: content });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.altSummCache, state.currentAltSummVariant, state.trackedItems]
  );

  const getAltSummFields = (content: AllessehContent, altSummVariant?: AltSummVariant) => {
    const selectedAltSummVariant = altSummVariant ?? state.currentAltSummVariant;
    const itemCache = state.altSummCache[selectedAltSummVariant]?.[content.data.id];

    const altSummFieldsTemp = {
      headline: itemCache?.headline ?? getDefaultHeadline(content, priorityAltSum),
      body: itemCache?.body ?? getDefaultBody(content, priorityAltSum),
      image: itemCache?.image ?? getDefaultImage(content, priorityAltSum),
      video: itemCache?.video ?? getDefaultVideo(content, priorityAltSum, publicationSettingsResp?.publicationSetting),
      bullets: itemCache?.bullets ?? getDefaultBullets(content, priorityAltSum),
      videoImage: itemCache?.videoImage ?? '',
      chart: getDefaultChartMedia(content, priorityAltSum)
        ? getDefaultChartMedia(content, priorityAltSum)
        : itemCache?.chart ?? '',
      chartlosId: itemCache?.chartlosId ?? getDefaultChartId(content, priorityAltSum),
      subhead: itemCache?.subhead ?? getDefaultSubhead(content, priorityAltSum),
      iframe: itemCache?.iframe ?? getDefaultIframeImage(content, priorityAltSum),
      proxy: itemCache?.proxy
    };

    const urlToShowType = getUrlToShowType(altSummFieldsTemp);

    let chart = urlToShowType === 'chart' ? itemCache?.chart ?? getDefaultChartMedia(content, priorityAltSum) : '';
    if (urlToShowType === 'chart') {
      const defaultChartMedia = getDefaultChartMedia(content, priorityAltSum);
      if (defaultChartMedia && !!chart) {
        chart = `${chart}?${defaultChartMedia.split('?')[1]}`;
      }
    }

    return {
      ...altSummFieldsTemp,
      chart,
      image: urlToShowType === 'image' ? itemCache?.image ?? getDefaultImage(content, priorityAltSum) : '',
      videoImage: urlToShowType === 'video' ? itemCache?.videoImage : '',
      iframe: urlToShowType === 'iframe' ? itemCache?.iframe ?? getDefaultIframeImage(content, priorityAltSum) : ''
    };
  };

  const setAltSummFields = (content: AllessehContent, fields: Omit<AltSummFields, 'proxy'>) => {
    dispatch({
      type: 'UPDATE_CONTENT_ITEM',
      payload: {
        id: content.data.id,
        fields: {
          /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
          headline: fields.headline || getDefaultHeadline(content, priorityAltSum),
          body: fields.body || getDefaultBody(content, priorityAltSum),
          image: fields.image || getDefaultImage(content, priorityAltSum),
          video: fields.video || getDefaultVideo(content, priorityAltSum, publicationSettingsResp?.publicationSetting),
          bullets: fields.bullets || getDefaultBullets(content, priorityAltSum),
          subhead: fields.subhead || getDefaultSubhead(content, priorityAltSum),
          videoImage: fields.videoImage || getDefaultVideoGuid(content, priorityAltSum),
          chart: fields.chart || getDefaultChartMedia(content, priorityAltSum),
          chartlosId: fields.chartlosId || getDefaultChartId(content, priorityAltSum),
          iframe: fields.iframe || getDefaultIframeImage(content, priorityAltSum)
          /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
        }
      }
    });
  };

  if (!isSummarianSettled) {
    // Show loader while Summarian is still loading
    return <ResponsiveLoader />;
  }

  const value = {
    selectedAltSummVariant: state.currentAltSummVariant,
    setSelectedAltSummVariant,
    trackContent,
    getAltSummFields,
    setAltSummFields,
    eventTarget: eventTarget.current
  };
  return <SummarianContext.Provider value={value}>{children}</SummarianContext.Provider>;
};
