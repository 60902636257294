import { AltSummFields } from 'contexts/summarian/summarianReducer';
import { SnippetyImageData, VideoDataForSummarian } from 'hooks/useSnippetyQuery';

export function getUrlToShowType(altSummFields?: AltSummFields | null): 'image' | 'video' | 'chart' | 'iframe' | '' {
  if (!altSummFields) {
    return '';
  }
  const { image, video, chartlosId, iframe } = altSummFields;
  if (iframe) {
    return 'iframe';
  }

  if (video) {
    return 'video';
  }
  if (chartlosId) {
    return 'chart';
  }
  if (image) {
    return 'image';
  }
  return '';
}

export function getUrlToShow(altSummFields: AltSummFields | null): string {
  if (!altSummFields) {
    return '';
  }
  const { image, videoImage, video, chart, chartlosId, iframe } = altSummFields;
  if (iframe) {
    return iframe;
  }
  if (videoImage && video) {
    return videoImage;
  }
  if (chart && chartlosId) {
    return chart;
  }
  if (image?.length) {
    return image;
  }
  return '';
}

export interface ControlledAltSumm {
  headline: string;
  body: string;
  image: SnippetyImageData[];
  video: VideoDataForSummarian[];
  imageUrl: string; // we don't send this property to Summarian, we only use it to display the image in the modal
  videoUrl: string; // we also do not send this to Summarian, only use it to display the video in the modal
  videoImage: string; // we do not send this to Summarian, only use it to display the video image
  bullets: string[];
  subhead: string;
  chartlosId: string;
  chart: string;
}
