/* eslint-disable import/no-cycle */
import { useEffect, useMemo } from 'react';
import { useHeaderData } from '@screentone/addon-auth-wrapper';
import { Box, Dropdown, IconThreeDotsVer, Typography } from '@screentone/core';
import classnames from 'classnames';

import ContentTypeIcon from 'components/content-type-token/ContentTypeToken';
import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { DuplicateItemType } from 'contexts/content-id-tracking/ContentIdTrackingContext';
import { useContentIdTracking } from 'contexts/content-id-tracking/useContentIdTracking';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { LinkedItem as LinkedItemModel } from 'data/generated/graphql';
import { useImage } from 'hooks/useImage';
import { extractSizeFromUrl, resizeIMImage } from 'utils/url';
import styles from './LinkedItem.module.scss';
import { LinkedItemForm } from './LinkedItemForm';
import { CommonContentProps } from '../commonContentProps';

const IM_WIDTH = {
  THUMBNAIL: 40,
  ENLARGED: 180
};
interface LinkedItemsProps extends CommonEntityProps, CommonContentProps {
  data: LinkedItemModel;
}

export const LinkedItem = ({
  data: linkedItem,
  onContentLoaded,
  parentHierarchyId,
  index,
  isDraft,
  isHistory
}: LinkedItemsProps) => {
  const { state } = useHeaderData();
  const { generateHierarchyId, isEditingLinkedItem } = useDataModelContext();
  const { renderActions } = useContextMenuActions();
  const { trackContentIds, ignoreContentIds, getDuplicateClassName } = useContentIdTracking();
  const hierarchyId = generateHierarchyId(linkedItem, parentHierarchyId, index);
  const actionsType = isHistory ? 'historyLinkedItem' : 'draftLinkedItem';

  useEffect(() => {
    if (onContentLoaded) {
      // Linked items don't have allesseh content, so it's fine just passing an empty object
      onContentLoaded(index!, [{}].length);
    }
  }, [index, onContentLoaded]);

  useEffect(() => {
    if (isDraft) {
      trackContentIds(hierarchyId, linkedItem.attributes.id);
    }

    return () => {
      ignoreContentIds(hierarchyId, linkedItem.attributes.id);
    };
  }, [hierarchyId, ignoreContentIds, isDraft, linkedItem.attributes.id, trackContentIds]);

  const duplicateClassName = isDraft
    ? getDuplicateClassName(
        state.headerConfig.currentTheme ?? 'light',
        DuplicateItemType.CARD,
        linkedItem.attributes.id
      )
    : '';
  const className = classnames(styles.card, duplicateClassName);

  const image = linkedItem.attributes.image ?? '';
  const { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage } = useImage();
  const imageUrlThumbnail = useMemo(() => resizeIMImage(image, { width: IM_WIDTH.THUMBNAIL, size: 1 }), [image]) ?? '';
  const imageUrlEnlarged = useMemo(() => resizeIMImage(image, { width: IM_WIDTH.ENLARGED }), [image]) ?? '';
  const enlargedHeight = IM_WIDTH.ENLARGED / extractSizeFromUrl(imageUrlEnlarged);

  if (isEditingLinkedItem === linkedItem.attributes.hosted_url) {
    return (
      <LinkedItemForm
        defaultHeadline={linkedItem.attributes.title}
        defaultMedia={linkedItem.attributes.image}
        defaultSummary={linkedItem.attributes.description}
        defaultUrl={linkedItem.attributes.hosted_url}
        defaultValidationBypassed={false}
        hierarchyId={hierarchyId}
      />
    );
  }

  return (
    <Box
      className={className}
      padding={{ all: 'sm' }}
      data-allesseh-id={`not-in-allesseh::${linkedItem.attributes.id}`}
      data-testid="page-content-card-container"
      data-model-hierarchy-id={hierarchyId}
    >
      <div className={styles.cardLeft} data-testid="content-card-container">
        <div
          onMouseEnter={debouncedHandleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.imageContainer}
          data-testid="content-card-image-container"
        >
          {showLargerImage && imageUrlThumbnail && (
            <div
              className={classnames(styles.imageEnlarged, styles.left)}
              style={{
                backgroundImage: `url('${imageUrlEnlarged}')`,
                height: enlargedHeight
              }}
              data-testid="larger-image"
            />
          )}
          <div
            style={{ backgroundImage: `url('${imageUrlThumbnail}')` }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            className={classnames(styles.image, imageUrlThumbnail ? '' : styles.noImage)}
            data-testid="thumbnail"
          />
        </div>
        <Typography
          variant="bodytext"
          margin={{ left: 'sm' }}
          className={styles.headline}
          data-testid="content-card-headline-label"
        >
          <ContentTypeIcon type="linkeditem" size="mlg" margin={{ right: 'sm' }} className={styles.icon} />
          {linkedItem.attributes.title}
        </Typography>
      </div>

      <Dropdown
        padding={{ all: 'none' }}
        position="right"
        trigger={<IconThreeDotsVer color="asphalt" />}
        data-testid="linked-item-ellipsis-icon"
      >
        {renderActions(actionsType, { hierarchyId, entity: linkedItem, index })}
      </Dropdown>
    </Box>
  );
};
