import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { PublicationSetting } from 'data/generated/graphql';
import {
  SnippetyChartData,
  SnippetyResponseWithChart,
  SnippetyResponseWithImage,
  SnippetyVideoData,
  useSnippetyQuery,
  VideoDataForSummarian
} from 'hooks/useSnippetyQuery';

import {
  CHART_ID_PATTERN,
  errorMessages,
  getChartData,
  getImageData,
  getVideoData,
  GUID_PATTERN,
  IMAGE_MANAGER_ID_PATTERN,
  MediaTypes
} from 'components/altsumm-modal/components/media-input/MediaInput.utils';

export function getDomain(publicationSettings: PublicationSetting | undefined, type: MediaTypes | '', id: string) {
  if (type === 'image' && !IMAGE_MANAGER_ID_PATTERN.test(id)) {
    return '';
  }
  if (type === 'video' && !GUID_PATTERN.test(id)) {
    return '';
  }
  if (type === 'chart' && !CHART_ID_PATTERN.test(id)) {
    return '';
  }
  switch (type) {
    case 'image':
      return `${publicationSettings?.snippetyIMDomain ?? ''}/${id}`;
    case 'chart':
      const modifiedId = id.startsWith('cdc_') ? id.replace('cdc_', '') : id;
      return `${publicationSettings?.snippetyChartDomain ?? ''}/${modifiedId}`;
    case 'video':
      return `${publicationSettings?.snippetyVideoDomain ?? ''}${id}`;
    default:
      return '';
  }
}

export const useMediaForm = (
  type: MediaTypes | '',
  onAdd: (data: any) => void,
  publicationSettings?: PublicationSetting
) => {
  const [isIdFormOpen, setIsIdFormOpen] = useState(false);
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const [idData, setIdData] = useState<SnippetyResponseWithImage | VideoDataForSummarian | SnippetyChartData | null>(
    null
  );

  const domain = getDomain(publicationSettings, type, id);

  const { data, error: idError, isLoading: isDataLoading } = useSnippetyQuery(domain);

  function isValid(currentId: string) {
    if (currentId.length === 0 || type === '') {
      return true;
    }

    if (type === 'image' && !IMAGE_MANAGER_ID_PATTERN.test(currentId)) {
      return false;
    }

    if (type === 'video' && !GUID_PATTERN.test(currentId)) {
      return false;
    }

    if (type === 'chart' && !CHART_ID_PATTERN.test(currentId)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (type === 'image' && data?.tools?.image) ||
      (type === 'video' && data) ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (type === 'chart' && data?.tools?.chart)
    ) {
      if (type === 'image') {
        setIdData(getImageData(data as SnippetyResponseWithImage));
      }
      if (type === 'video') {
        setIdData(getVideoData(data as SnippetyVideoData));
      }
      if (type === 'chart') {
        setIdData(getChartData(data as SnippetyResponseWithChart));
      }
    }
  }, [data, setIdData, type]);

  useEffect(() => {
    if (id.length === 0) {
      setError('');
    } else if (!domain && id.length > 0 && type !== '' && error !== errorMessages[type].invalidId && isIdFormOpen) {
      setError(errorMessages[type].invalidId);
    } else if (idError && type !== '' && idError !== error) {
      setError((idError as AxiosError<{ message: string }>).response?.data.message ?? errorMessages[type].serverError);
    }
  }, [idError, type, error, domain, id, isIdFormOpen]);

  const onDelete = () => {
    setId('');
    onAdd(null);
  };

  const onIdAdd = () => {
    if (id.length === 0) {
      if (type in errorMessages) {
        setError(errorMessages[type as keyof typeof errorMessages].invalIdInput);
      }
      return;
    }

    if (type === 'image' && !IMAGE_MANAGER_ID_PATTERN.test(id)) {
      setError(errorMessages.image.invalidId);
      return;
    }

    if (type === 'video' && !GUID_PATTERN.test(id)) {
      setError(errorMessages.video.invalidId);
      return;
    }

    if (type === 'chart' && !CHART_ID_PATTERN.test(id)) {
      setError(errorMessages.chart.invalidId);
      return;
    }

    onAdd(idData);
    setId('');
    setIsIdFormOpen(false);
  };

  const onIdChange = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e === null) {
      setId('');
    } else {
      if (isValid(e.target.value)) {
        setError('');
      }
      setId(e.target.value);
    }
  };

  const shouldAdd = !!(!isDataLoading && !idError && id.length > 0);
  return {
    id,
    error,
    onDelete,
    onIdAdd,
    onIdChange,
    isIdFormOpen,
    shouldAdd,
    setIsIdFormOpen
  };
};
