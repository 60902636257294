import React, { ChangeEventHandler, ReactElement, useState } from 'react';
import { Box, Input, Typography, Wrapper } from '@screentone/core';

import { HelpIcon, SortDropdown } from 'components';
import SearchContentFiltersContainer, {
  SearchContentFilters,
  ShowFilterOptions
} from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { useConvertedProperty } from 'hooks';
import { AllessehContent, AllessehQuerySortKeyTypes, AllessehQuerySortOrderType } from 'hooks/useAllessehContentQuery';
import SearchByQueryTerm from './components/search-by-query-term/searchByQueryTerm';
import SearchByUrl from './components/search-by-url/SearchByUrl';
import styles from './SearchContent.module.scss';

export interface SearchParams {
  search: string;
  filters: SearchContentFilters;
  sort: AllessehQuerySortKeyTypes;
  sortOrder?: AllessehQuerySortOrderType;
  refreshTimestamp?: number;
}

export interface SearchProps {
  searchParams: SearchParams;
  onSearchParamsChange: (changedParams: SearchParams) => void;
  resultListClassName?: string;
  renderContentCard: (content: AllessehContent, index: number) => ReactElement;
  defaultFilterState: SearchContentFilters;
  showFilters?: ShowFilterOptions;
}

export interface SearchContentProps {
  searchProps: SearchProps;
  beforeContent?: React.ReactNode;
  embargo?: boolean;
}

const FILTER_MAPPING: { [key: string]: string[] } = {
  marketwatch: ['author', 'sectionType']
};

const getFiltersForDisplay = ({
  currentProperty,
  searchParams
}: {
  currentProperty: string | null;
  searchParams: SearchParams;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!!currentProperty && FILTER_MAPPING[currentProperty]) {
    const displayFilters: any[] = [];

    FILTER_MAPPING[currentProperty].forEach((filterKey) => {
      if (searchParams.filters[filterKey]) {
        displayFilters.push(searchParams.filters[filterKey]);
      }
    });

    const filterString = displayFilters.join(', ');
    return filterString;
  }

  return '';
};

export const SearchContent = ({ searchProps, beforeContent = null, embargo = false }: SearchContentProps) => {
  const {
    searchParams,
    onSearchParamsChange,
    resultListClassName,
    renderContentCard,
    defaultFilterState,
    showFilters
  } = searchProps;
  const [searchByUrl, setSearchByUrl] = useState(false);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.includes('http')) setSearchByUrl(true);
    else setSearchByUrl(false);
    onSearchParamsChange({ ...searchParams, search: e.target.value });
  };

  const handleFiltersChange = (newFilters: Partial<SearchContentFilters>) => {
    onSearchParamsChange({ ...searchParams, filters: { ...searchParams.filters, ...newFilters } });
  };

  const handleSortChange = (newSort: AllessehQuerySortKeyTypes) => {
    onSearchParamsChange({ ...searchParams, sort: newSort });
  };

  const handleRefresh = () => {
    onSearchParamsChange({ ...searchParams, refreshTimestamp: Date.now() });
  };

  const searchContentByProps = { searchParams, resultListClassName, renderContentCard };

  const currentProperty = useConvertedProperty();

  const filterString = getFiltersForDisplay({ currentProperty, searchParams });

  return (
    <>
      {!embargo && (
        <Box
          data-testid="search-content-container"
          padding={{ all: 'sm' }}
          margin={{ top: 'sm' }}
          className={styles.searchBox}
        >
          <div data-testid="search-content-filter" className={styles.searchInput}>
            <Input
              data-testid="page-search-content-input"
              type="text"
              placeholder="Search"
              value={searchParams.search}
              onChange={handleSearchChange}
              margin={{ right: 'sm' }}
            />
            <HelpIcon
              data-testid="search-content-help-icon"
              className={styles.searchHelpIcon}
              text="Search by ID, url, keyword, page, product, or section."
            />
          </div>
          <SortDropdown sortEnum={AllessehQuerySortKeyTypes} value={searchParams.sort} onChange={handleSortChange} />
        </Box>
      )}
      {!embargo && (
        <SearchContentFiltersContainer
          filters={searchParams.filters}
          onChangeFilters={handleFiltersChange}
          defaultFilterState={defaultFilterState}
          showFilters={showFilters}
        />
      )}

      {filterString && <Typography variant="label3">Filters: {filterString}</Typography>}
      <Wrapper data-testid="search-content-search-by-container" className={styles.searchContentWrapper}>
        {beforeContent}
        {searchByUrl ? (
          <SearchByUrl {...searchContentByProps} />
        ) : (
          <SearchByQueryTerm {...searchContentByProps} handleRefresh={handleRefresh} embargo={embargo} />
        )}
      </Wrapper>
    </>
  );
};
