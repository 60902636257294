import { createRef, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  IconEdit,
  IconExternalLink,
  IconThreeDotsVer,
  IconTrash,
  Input,
  List,
  Wrapper
} from '@screentone/core';

import { useSnippetyQuery } from 'hooks/useSnippetyQuery';
import { isValidURL } from 'utils/url';
import styles from './BulletInput.module.scss';

interface BulletInputProps {
  bullets: string[];
  handleUpdateBullets: (bullets: string[]) => void;
  displayNewBulletInput: boolean;
  setDisplayNewBulletInput: (displayNewBulletInput: boolean) => void;
}

export const BulletInput = ({
  bullets,
  handleUpdateBullets,
  displayNewBulletInput,
  setDisplayNewBulletInput
}: BulletInputProps) => {
  const [newBullet, setNewBullet] = useState('');
  const [editInputIndex, setEditInputIndex] = useState<number | undefined>(undefined);
  const { data: articleData } = useSnippetyQuery(newBullet, { enabled: isValidURL(newBullet) });
  const bulletRefs = useMemo(
    () => Array.from({ length: bullets.length }).map(() => createRef<HTMLTextAreaElement>()),
    [bullets.length]
  );

  const formatBulletLink = (bullet: string, title?: string) => `<a href="${bullet}">${title ?? bullet}</a>`;
  const scriptTagRegex = /<a[^>]*href=["']([^"']*)["']/g;
  const handleNewBulletInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBullet(e.target.value);
  };

  const handleNewBulletEnter = (e: React.KeyboardEvent) => {
    const { key } = e;
    if (key === 'Enter') {
      const bulletLink = articleData?.title
        ? formatBulletLink(newBullet, articleData.title)
        : formatBulletLink(newBullet);
      handleUpdateBullets([...bullets, bulletLink]);
      setNewBullet('');
      setDisplayNewBulletInput(false);
    }
  };

  useEffect(() => {
    if (bulletRefs.length > 0) {
      bulletRefs.forEach((bulletRefItem, bulletIndex) => {
        if (bulletRefs[bulletIndex].current) {
          bulletRefs[bulletIndex].current!.style.height = '0';
          bulletRefs[bulletIndex].current!.style.height = `${bulletRefs[bulletIndex].current!.scrollHeight}px`;
        }
      });
    }
  }, [bulletRefs]);

  const handleRemoveBullet = (index: number) => {
    const newBullets = [...bullets];
    newBullets.splice(index, 1);
    handleUpdateBullets(newBullets);
  };

  const handleEditBulletIndex = (index: number) => {
    setEditInputIndex(index);
  };

  const handleEditBulletInput = (
    e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>,
    index: number,
    bulletHeadline?: boolean
  ) => {
    if (bulletHeadline) {
      if (bulletRefs[index]) {
        bulletRefs[index].current!.style.height = '0';
        bulletRefs[index].current!.style.height = `${bulletRefs[index].current!.scrollHeight}px`;
      }
      const newBullets = [...bullets];
      const bulletLink = scriptTagRegex.exec(newBullets[index]);
      if (bulletLink) {
        newBullets[index] = formatBulletLink(bulletLink[1], e.target.value);
      }
      handleUpdateBullets(newBullets);
    } else {
      const newBullets = [...bullets];
      newBullets[index] = formatBulletLink(e.target.value);
      handleUpdateBullets(newBullets);
    }
  };

  const handleEditBulletEnter = (e: React.KeyboardEvent) => {
    const { key } = e;
    if (key === 'Enter') {
      setEditInputIndex(-1);
    }
  };

  const handleViewBulletLink = (bulletLink: string) => {
    window.open(bulletLink, '_blank');
  };

  return (
    <>
      <List listStyle="bullet">
        {[...bullets].map((bullet: string, index: number) => {
          if (!bullet) return null;
          const link = bullet.split('">')[0].slice(9);
          const headline = bullet.split('">')[1].slice(0, -4);
          const bulletString = headline.length ? headline : link;
          return (
            <List.Item key={index}>
              <Wrapper className={styles.bulletItemWrapper}>
                <Wrapper className={styles.bulletGroup}>
                  {editInputIndex === index && (
                    <Input
                      data-testid="edit-bullet-input"
                      defaultValue={link}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleEditBulletInput(e, index);
                      }}
                      onKeyDown={handleEditBulletEnter}
                    />
                  )}
                  <textarea
                    hidden={editInputIndex === index && !headline.length}
                    value={bulletString}
                    placeholder="Add article link"
                    className={styles.bulletItem}
                    ref={bulletRefs[index]}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleEditBulletInput(e, index, true);
                    }}
                  />
                </Wrapper>
                <Dropdown
                  padding={{ all: 'none' }}
                  position="right"
                  zIndex="top"
                  trigger={<IconThreeDotsVer color="asphalt" />}
                >
                  <Button
                    onClick={() => {
                      handleRemoveBullet(index);
                    }}
                    margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                    tertiary
                    icon={IconTrash as SvgComponent}
                  >
                    Remove Bullet
                  </Button>
                  <Button
                    onClick={() => {
                      handleViewBulletLink(bulletString);
                    }}
                    margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                    tertiary
                    icon={IconExternalLink as SvgComponent}
                  >
                    Open Link
                  </Button>
                  <Button
                    onClick={() => {
                      handleEditBulletIndex(index);
                    }}
                    margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                    tertiary
                    icon={IconEdit as SvgComponent}
                  >
                    Edit Link
                  </Button>
                </Dropdown>
              </Wrapper>
            </List.Item>
          );
        })}
      </List>
      {displayNewBulletInput && (
        <List listStyle="bullet">
          <List.Item>
            <Input
              data-testid="edit-bullet-input"
              value={newBullet}
              onChange={handleNewBulletInput}
              onKeyDown={handleNewBulletEnter}
            />
            <Dropdown padding={{ all: 'none' }} position="right" trigger={<IconThreeDotsVer color="asphalt" />}>
              <Button
                margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                tertiary
                icon={IconTrash as SvgComponent}
                onClick={() => {
                  setNewBullet('');
                  setDisplayNewBulletInput(false);
                }}
              >
                Remove Bullet
              </Button>
            </Dropdown>
          </List.Item>
        </List>
      )}
    </>
  );
};
