import { useEffect, useMemo } from 'react';
import { IconDisabled, Token } from '@screentone/core';
import classnames from 'classnames';

import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import useDebouncedState from 'hooks/useDebouncedState';
import { extractSizeFromUrl, resizeIMImage } from 'utils/url';
import styles from './ContentCard.module.scss';
import { ContentCardHeadline } from './ContentCardHeadline/ContentCardHeadline';
import { useImage } from '../../../../hooks/useImage';

import { getUrlToShow } from 'components/altsumm-modal/AltSummModal.utils';

const IM_WIDTH = {
  THUMB: 40,
  ENLARGED: 180
};
interface ContentCardProps {
  content: AllessehContent;
  isIconPresent: boolean;
  isSearch?: boolean;
}

export const ContentCard = ({ content, isIconPresent, isSearch = false }: ContentCardProps) => {
  const { trackContent, getAltSummFields } = useSummarianContext();
  const { headline } = getAltSummFields(content);

  const debouncedAltSummFields = useDebouncedState(getAltSummFields(content), 300, ['proxy']);

  const urlToShow = getUrlToShow(debouncedAltSummFields);

  useEffect(() => {
    trackContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  const { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage } = useImage();
  const imageUrl =
    useMemo(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      () => resizeIMImage(urlToShow, { width: IM_WIDTH.THUMB, size: 1 }),
      [urlToShow]
    ) ?? '';

  const imageUrlEnlarged =
    useMemo(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      () => resizeIMImage(urlToShow, { width: IM_WIDTH.ENLARGED }),
      [urlToShow]
    ) ?? '';
  const enlargedHeight = IM_WIDTH.ENLARGED / extractSizeFromUrl(imageUrlEnlarged);

  return (
    <div data-testid="content-card-container" className={styles.cardLeft}>
      <div
        data-testid="content-card-image-container"
        onMouseEnter={debouncedHandleMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className={styles.imageContainer}
      >
        {showLargerImage && imageUrl && (
          <div
            data-testid="larger-image"
            className={classnames(styles.imageEnlarged, isSearch ? '' : `${styles.left}`)}
            style={{
              backgroundImage: `url('${imageUrlEnlarged}')`,
              height: enlargedHeight
            }}
          />
        )}
        <div
          data-testid="thumbnail"
          style={{ backgroundImage: `url('${imageUrl}')` }}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          className={classnames(styles.image, imageUrl ? '' : styles.noImage)}
        />
      </div>
      <ContentCardHeadline isIconPresent={isIconPresent} headline={headline} content={content} />

      {content.data.attributes.content_status === 'embargo' && (
        <Token icon={IconDisabled as SvgComponent} margin={{ left: 'sm' }} color="lava" />
      )}
    </div>
  );
};
