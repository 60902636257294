/* eslint-disable import/no-cycle */
import { useEffect, useMemo } from 'react';
import { useHeaderData } from '@screentone/addon-auth-wrapper';
import {
  Box,
  Dropdown,
  Group,
  IconBarrons,
  IconDisabled,
  IconFnlondon,
  IconMansionglobal,
  IconMarketwatch,
  IconPen,
  IconThreeDotsVer,
  IconWsj,
  IconWsjChina,
  IconWsjcommerce,
  IconWsjJapan,
  IconWsjopinion,
  Token,
  Typography
} from '@screentone/core';
import classNames from 'classnames';

import ContentTypeToken from 'components/content-type-token/ContentTypeToken';
import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { DuplicateItemType } from 'contexts/content-id-tracking/ContentIdTrackingContext';
import { useContentIdTracking } from 'contexts/content-id-tracking/useContentIdTracking';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ArticleItem, PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import useDebouncedState from 'hooks/useDebouncedState';
import { useImage } from 'hooks/useImage';
import { formatLocalDate } from 'utils/dates';
import { safelyParseContent } from 'utils/temp';
import { extractSizeFromUrl, resizeIMImage } from 'utils/url';
import styles from './Article.module.scss';
import { CommonContentProps } from '../commonContentProps';

import { getUrlToShow } from 'components/altsumm-modal/AltSummModal.utils';

const IM_WIDTH = {
  THUMBNAIL: 40,
  ENLARGED: 180
};

export const isOpinion = (content: AllessehContent) => content.data.attributes.section_name === 'Opinion';

const getType = (content: AllessehContent) => {
  if (content.data.attributes.product.includes('Live Coverage'))
    return PublicationSettingSearchableContentType.LiveCoverage.toLowerCase();
  if (isOpinion(content)) return PublicationSettingSearchableContentType.Opinion.toLowerCase();
  switch (content.data.attributes.product) {
    case 'Interactive Media':
      return PublicationSettingSearchableContentType.Tappable.toLowerCase();
    case 'MarketWatch.com':
      return PublicationSettingSearchableContentType.Marketwatch.toLowerCase();
    case 'Barrons Blogs':
      return PublicationSettingSearchableContentType.Penta.toLowerCase();
    case 'Mansion Global':
      return PublicationSettingSearchableContentType.MansionGlobal.toLowerCase();
    case 'Agence France Presse':
      return PublicationSettingSearchableContentType.Afp.toLowerCase();
    default:
      return content.data.type;
  }
};

// TODO: brand icon has to be based on allesseh "products"
const brandIcon = (brand: string) => {
  const props = { size: 'lg', className: styles.image };
  switch (brand) {
    case 'Barrons.com':
    case 'Barrons Blogs':
    case 'Barrons Live Coverage':
    case 'Barrons Broadband Podcast':
    case 'Barrons Broadband Video':
    case 'Barrons.com Webstory':
    case 'Agence France Presse':
      return <IconBarrons {...props} />;
    case 'WSJ.com':
    case 'Interactive Media':
    case 'WSJ Live Coverage':
    case 'WSJ Video':
    case 'WSJ Audio':
    case 'WSJ.com Webstory':
      return <IconWsj {...props} />;
    case 'MarketWatch.com':
    case 'MarketWatch Live Coverage':
      return <IconMarketwatch {...props} />;
    case 'PENEWS':
      return <IconPen {...props} />;
    case 'FN Online':
      return <IconFnlondon {...props} />;
    case 'Mansion Global':
      return <IconMansionglobal {...props} />;
    case 'WSJ Japanese':
      return <IconWsjJapan {...props} />;
    case 'cwsj':
      return <IconWsjChina {...props} />;
    case 'WSJ Opinion':
      return <IconWsjopinion {...props} />;
    case 'wsjcommerce':
      return <IconWsjcommerce {...props} />;
    case 'YDJ':
    default:
      return <IconWsj {...props} />;
  }
};

export interface ArticleProps extends CommonEntityProps, CommonContentProps {
  data: ArticleItem;
  noBorder?: boolean;
  boxPadding?: 'xs' | 'sm' | 'smd' | 'md' | 'mlg' | 'lg' | 'xl';
  renderActions?: () => React.ReactNode;
  parentQueryHierarchyId?: string;
}

export const Article = ({
  data: article,
  onContentLoaded,
  isDraft,
  noBorder = false,
  boxPadding = 'sm',
  renderActions,
  parentQueryHierarchyId,
  parentHierarchyId,
  index,
  // eslint-disable-next-line unused-imports/no-unused-vars
  isHistory
}: ArticleProps) => {
  const { state } = useHeaderData();
  const { generateHierarchyId } = useDataModelContext();
  const { trackContentIds, ignoreContentIds, getDuplicateClassName } = useContentIdTracking();
  const { trackContent, getAltSummFields } = useSummarianContext();
  const hierarchyId = generateHierarchyId(article, parentHierarchyId, index);
  const ignoreHierarchyIds = parentQueryHierarchyId ? [parentQueryHierarchyId] : [];

  const duplicateClassName = isDraft
    ? getDuplicateClassName(
        state.headerConfig.currentTheme ?? 'light',
        DuplicateItemType.CARD,
        article.attributes.id,
        ignoreHierarchyIds
      )
    : '';

  const allessehContent = useMemo(() => safelyParseContent<AllessehContent>(article.content), [article.content]);
  const actionButtons = renderActions?.();

  const { headline } = getAltSummFields(allessehContent);

  const debouncedAltSummFields = useDebouncedState(getAltSummFields(allessehContent), 300, ['proxy']);

  const urlToShow = getUrlToShow(debouncedAltSummFields);

  useEffect(() => {
    trackContent(allessehContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  useEffect(() => {
    if (isDraft) {
      trackContentIds(hierarchyId, article.attributes.id);

      return () => {
        ignoreContentIds(hierarchyId, article.attributes.id);
      };
    }

    return () => {};
  }, [article.attributes.id, hierarchyId, ignoreContentIds, isDraft, trackContentIds]);

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(index!, [allessehContent].length);
    }
  }, [allessehContent, index, onContentLoaded]);

  const { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage } = useImage();
  const imageUrlThumbnail =
    useMemo(() => resizeIMImage(urlToShow, { width: IM_WIDTH.THUMBNAIL, size: 1 }), [urlToShow]) ?? '';
  const imageUrlEnlarged = useMemo(() => resizeIMImage(urlToShow, { width: IM_WIDTH.ENLARGED }), [urlToShow]) ?? '';
  const enlargedHeight = IM_WIDTH.ENLARGED / extractSizeFromUrl(imageUrlEnlarged);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const displayDate = formatLocalDate(allessehContent.data.attributes.updated_datetime_utc ?? '');

  return (
    <Box
      padding={{ all: boxPadding }}
      className={classNames(noBorder ? styles.noBorder : '', duplicateClassName)}
      data-allesseh-id={allessehContent.data.id}
      data-testid="page-content-card-container"
      data-model-hierarchy-id={hierarchyId}
    >
      <Group gap="none">
        <div
          onMouseEnter={debouncedHandleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.imageContainer}
          data-testid="content-card-image-container"
        >
          {showLargerImage && imageUrlThumbnail && (
            <div
              className={classNames(styles.imageEnlarged, isDraft ? styles.left : '')}
              style={{
                backgroundImage: `url('${imageUrlEnlarged}')`,
                height: enlargedHeight
              }}
              data-testid="larger-image"
            />
          )}
          {imageUrlThumbnail ? (
            <div
              style={{ backgroundImage: `url('${imageUrlThumbnail}')` }}
              className={classNames(styles.image, urlToShow ? '' : styles.noImage)}
              data-testid="thumbnail"
            />
          ) : (
            brandIcon(article.attributes.product)
          )}
        </div>

        <Typography
          componentEl="div"
          variant="bodytext"
          margin={{ left: 'sm' }}
          className={styles.headline}
          data-testid="content-card-headline-label"
        >
          {headline} {isOpinion(allessehContent) && <Token color="gray">Opinion</Token>}
          <ContentTypeToken type={getType(allessehContent)} size="mlg" margin={{ right: 'xs' }} color="gray" />
          {allessehContent.data.attributes.content_status === 'embargo' && (
            <Token icon={IconDisabled as SvgComponent} margin={{ right: 'xs' }} color="lava" />
          )}
          <Typography inline variant="note" margin={{ left: 'xs' }}>
            {displayDate}
          </Typography>
        </Typography>

        {actionButtons && (
          <Dropdown
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
            data-testid="page-content-ellipsis-icon"
          >
            {actionButtons}
          </Dropdown>
        )}
      </Group>
    </Box>
  );
};
