import { Box, FormLabel, Input, Typography } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { Metadata, PageModule } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import styles from './PageModuleTitle.module.scss';

interface PageModuleTitleProps {
  pageModule: PageModule;
  onChange: (newPageModule: PageModule) => void;
  viewOnly?: boolean;
}

export const PageModuleTitle = ({ pageModule, onChange, viewOnly }: PageModuleTitleProps) => {
  const { data: publicationSettingsResp } = usePublicationSettings();

  if (!pageModule.uiModuleFields.titleModule) return null;

  const allowedMeta = pageModule.uiModuleFields.titleModule.allowedMeta ?? [
    Metadata.Title,
    Metadata.Subtitle,
    Metadata.SubtitleUrl
  ];

  const handleChangeStringField =
    (fieldKey: 'title' | 'titleUrl' | 'subtitle' | 'subtitleUrl') => (e: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = e.target;
      if (['titleUrl', 'subtitleUrl'].includes(fieldKey)) {
        const firstChar = value[0];
        if (firstChar !== '/') {
          value = `/${value}`;
        }
      }
      const newPageModule = clonedeep(pageModule);
      if (newPageModule.uiModuleFields.titleModule) {
        newPageModule.uiModuleFields.titleModule[fieldKey] = value;
      }
      onChange(newPageModule);
    };

  return (
    <>
      {allowedMeta.includes(Metadata.Title) && (
        <FormLabel key="title" label="Title" fullWidth>
          <Input
            placeholder="Enter title"
            value={pageModule.uiModuleFields.titleModule.title}
            margin={{ bottom: 'sm' }}
            onChange={handleChangeStringField('title')}
            disabled={viewOnly}
            data-testid="page-enter-title"
          />
        </FormLabel>
      )}
      {allowedMeta.includes(Metadata.TitleUrl) && (
        <FormLabel key="titleUrl" label="Title URL Path" fullWidth margin={{ top: 'sm' }}>
          <div className={styles.subtitleUrlForm}>
            <Box className={styles.baseRouteBox} padding={{ left: 'xs', right: 'xs' }}>
              <Typography>{publicationSettingsResp?.publicationSetting.baseRoute}/</Typography>
            </Box>
            <Input
              placeholder="Enter title url path"
              value={pageModule.uiModuleFields.titleModule.titleUrl?.slice(1)}
              margin={{ bottom: 'sm' }}
              onChange={handleChangeStringField('titleUrl')}
              disabled={viewOnly}
              data-testid="page-enter-subtitle-url"
            />
          </div>
        </FormLabel>
      )}
      {allowedMeta.includes(Metadata.Subtitle) && (
        <FormLabel key="subtitle" label="Subtitle" fullWidth margin={{ top: 'sm' }}>
          <Input
            placeholder="Enter subtitle"
            value={pageModule.uiModuleFields.titleModule.subtitle}
            margin={{ bottom: 'sm' }}
            onChange={handleChangeStringField('subtitle')}
            disabled={viewOnly}
            data-testid="page-enter-subtitle"
          />
        </FormLabel>
      )}
      {allowedMeta.includes(Metadata.SubtitleUrl) && (
        <FormLabel key="subtitleUrl" label="Subtitle URL Path" fullWidth margin={{ top: 'sm' }}>
          <div className={styles.subtitleUrlForm}>
            <Box className={styles.baseRouteBox} padding={{ left: 'xs', right: 'xs' }}>
              <Typography>{publicationSettingsResp?.publicationSetting.baseRoute}/</Typography>
            </Box>
            <Input
              placeholder="Enter subtitle url path"
              value={pageModule.uiModuleFields.titleModule.subtitleUrl?.slice(1)}
              margin={{ bottom: 'sm' }}
              onChange={handleChangeStringField('subtitleUrl')}
              disabled={viewOnly}
              data-testid="page-enter-subtitle-url"
            />
          </div>
        </FormLabel>
      )}
    </>
  );
};
