import { useEffect, useState } from 'react';
import { format } from 'date-fns-tz';

import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import {
  AllessehContentType,
  ArticleItem,
  ArticleItemInput,
  OffPlatformDto,
  OffPlatformListContainer,
  OffPlatformUpdateInput,
  ScheduledContentCancelInput,
  ScheduledContentCreateInput,
  ScheduledContentRescheduleInput,
  ScheduledContentUpdateInput,
  useCancelScheduledContentMutation,
  useRescheduleContentMutation,
  useScheduleContentMutation,
  useUpdateOffPlatformMutation,
  useUpdateScheduledContentMutation
} from 'data/generated/graphql';
import { prepContentItemFromOffPlatform } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { useConvertedProperty } from 'hooks';

export const useOffPlatformPublish = () => {
  const [notificationText, setNotificationText] = useState('');
  const [savedForScheduleEdit, setSavedForScheduleEdit] = useState<OffPlatformDto | null>(null);
  const [isRecentlyPublish, setIsRecentlyPublish] = useState(false);

  const { alertError, removeAlert, alertSuccess } = useAlert();
  const currentProperty = useConvertedProperty();
  const { getDTO, setNewRoot, setNewMetadata, resetModelChanged } = useDataModelContext<OffPlatformListContainer>();

  const { mutateAsync: publishingAsync, isLoading: isPublishing } = useUpdateOffPlatformMutation();
  const { mutateAsync: scheduleAsync, isLoading: isScheduling } = useScheduleContentMutation();
  const { mutateAsync: rescheduleAsync, isLoading: isRescheduling } = useRescheduleContentMutation();
  const { mutateAsync: updateScheduleAsync, isLoading: isUpdatingSchedule } = useUpdateScheduledContentMutation();
  const { mutateAsync: cancelScheduleAsync, isLoading: isDeleting } = useCancelScheduledContentMutation();

  const handlePublish = async () => {
    const { metadata, root } = getDTO() as OffPlatformDto;

    removeAlert();

    const updateInput: OffPlatformUpdateInput = {
      allessehId: metadata.allessehId,
      name: metadata.name,
      notes: metadata.notes,
      consumer: metadata.consumer,
      createdUtc: metadata.createdUtc,
      publicationKey: currentProperty ?? '',
      collection: root.collection.map<ArticleItemInput>((item) => prepContentItemFromOffPlatform(item as ArticleItem))
    };

    try {
      await publishingAsync({ offPlatformUpdateInput: updateInput });

      setNotificationText('Saved');
      setIsRecentlyPublish(true);
      alertSuccess('Off platform successfully saved');
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not update the off platform. ${e.message}`);
      } else {
        alertError('Could not update the off platform.');
      }
    }
  };

  const handleSchedule = async (publishUtc: number) => {
    const offPlatform = getDTO();

    offPlatform.metadata.publishUtc = publishUtc;

    removeAlert();

    const scheduleInput: ScheduledContentCreateInput = {
      allessehId: offPlatform.metadata.allessehId,
      contentType: AllessehContentType.OffPlatform,
      publishUtc,
      body: offPlatform
    };

    try {
      await scheduleAsync({ scheduledContentCreateInput: scheduleInput, publicationKey: currentProperty ?? '' });

      resetModelChanged();
      setNotificationText('Scheduled');
      alertSuccess(`Scheduled off-platform module for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not schedule the off-platform module. ${e.message}`);
      } else {
        alertError('Could not schedule the off-platform module.');
      }
    }
  };

  const showEditSchedule = (history: OffPlatformDto) => {
    const current = getDTO() as OffPlatformDto;
    setSavedForScheduleEdit(current);
    setNewRoot(history.root);
    setNewMetadata(history.metadata);
  };

  const hideEditSchedule = () => {
    if (savedForScheduleEdit) {
      setNewRoot(savedForScheduleEdit.root);
      setNewMetadata(savedForScheduleEdit.metadata);
      setSavedForScheduleEdit(null);
    }
  };

  const handleReschedule = async (publishUtc: number) => {
    const offPlatform = getDTO() as OffPlatformDto;

    const previousPublishUtc = offPlatform.metadata.publishUtc!;
    offPlatform.metadata.publishUtc = publishUtc;

    const input: ScheduledContentRescheduleInput = {
      allessehId: offPlatform.metadata.allessehId,
      contentType: AllessehContentType.OffPlatform,
      previousPublishUtc,
      newPublishUtc: publishUtc,
      body: offPlatform
    };

    try {
      await rescheduleAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentRescheduleInput: input
      });

      resetModelChanged();
      setNotificationText('Scheduled');
      alertSuccess(`Rescheduled off-platform module for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);
      hideEditSchedule();
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not reschedule the off-platform module. ${e.message}`);
      } else {
        alertError('Could not reschedule the off-platform module.');
      }
    }
  };

  const handleUpdateSchedule = async () => {
    const offPlatform = getDTO() as OffPlatformDto;

    const input: ScheduledContentUpdateInput = {
      allessehId: offPlatform.metadata.allessehId,
      publishUtc: offPlatform.metadata.publishUtc!,
      contentType: AllessehContentType.OffPlatform,
      body: offPlatform
    };

    try {
      await updateScheduleAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentUpdateInput: input
      });

      resetModelChanged();
      setNotificationText('Scheduled');
      alertSuccess('Rescheduled off-platform module has been updated.');
      hideEditSchedule();
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not update the schedule the off-platform module. ${e.message}`);
      } else {
        alertError('Could not update the schedule the off-platform module.');
      }
    }
  };

  const handleDeleteSchedule = async () => {
    const offPlatform = getDTO() as OffPlatformDto;

    const input: ScheduledContentCancelInput = {
      allessehId: offPlatform.metadata.allessehId,
      publishUtc: offPlatform.metadata.publishUtc!
    };

    try {
      await cancelScheduleAsync({ publicationKey: currentProperty ?? '', scheduledContentCancelInput: input });

      resetModelChanged();
      setNotificationText('Scheduled');
      alertSuccess('Scheduled Page has been deleted');
      hideEditSchedule();
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not delete the schedule the off-platform module. ${e.message}`);
      } else {
        alertError('Could not delete the schedule the off-platform module.');
      }
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (notificationText) {
      timeout = setTimeout(() => {
        setNotificationText('');
        setIsRecentlyPublish(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [notificationText]);

  return {
    handlePublish,
    handleSchedule,
    isInEditMode: !!savedForScheduleEdit,
    showEditSchedule,
    hideEditSchedule,
    handleReschedule,
    handleUpdateSchedule,
    notificationText,
    isRecentlyUpdated: notificationText !== '',
    isRecentlyPublish,
    handleDeleteSchedule,
    isMutationInProgress: isPublishing || isScheduling || isRescheduling || isUpdatingSchedule || isDeleting,
    savedForScheduleEdit
  };
};
