import { useEffect } from 'react';
import { Group, Typography, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { OffPlatformListContainer } from 'data/generated/graphql';
import MultiTabSection from './components/off-platform-collection-multitab-section/OffPlatformCollectionMultitabSection';
import PublishingButtons from './components/off-platform-collection-publishing-buttons/OffPlatformCollectionPublishingButtons';
import { useOffPlatformPublish } from './hooks/useOffPlatformPublish';
import styles from './OffPlatformEdit.module.scss';

export const OffPlatformEdit = ({ onUpdate }: { onUpdate: () => void }) => {
  const {
    root: data,
    metadata,
    renderEntity,
    hasModelChanged,
    resetModelChanged,
    setNewMetadata
  } = useDataModelContext<OffPlatformListContainer>();

  const {
    isMutationInProgress,
    isInEditMode,
    notificationText,
    handlePublish,
    handleSchedule,
    hideEditSchedule,
    showEditSchedule,
    handleUpdateSchedule,
    handleReschedule,
    savedForScheduleEdit,
    handleDeleteSchedule,
    isRecentlyUpdated,
    isRecentlyPublish
  } = useOffPlatformPublish();

  useEffect(() => {
    if (isRecentlyUpdated) {
      onUpdate();
    }
  }, [isRecentlyUpdated, onUpdate]);

  const handleScheduleClick = async (publishUtc: number) => {
    if (isInEditMode) {
      await handleReschedule(publishUtc);
    } else {
      await handleSchedule(publishUtc);
    }
  };

  const handleClickPublish = async () => {
    if (isInEditMode) {
      await handleUpdateSchedule();
    } else {
      await handlePublish();
    }

    resetModelChanged();
  };

  const handleUpdateName = (name: string) => {
    setNewMetadata({ name });
  };

  return (
    <>
      <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.secondHeader}>
        <div>
          <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="off-platform-header-title">
            {metadata.name}
          </Typography>
          {notificationText && (
            <Typography color="asphalt" margin={{ left: 'md' }}>
              {notificationText}
            </Typography>
          )}
        </div>
        <PublishingButtons
          hasCollectionChanged={hasModelChanged}
          isPerformingAction={isMutationInProgress}
          isInEditMode={isInEditMode}
          onPublish={handleClickPublish}
          onSchedule={handleScheduleClick}
          onCancelEdit={hideEditSchedule}
          onDelete={handleDeleteSchedule}
        />
      </Wrapper>
      <Wrapper margin={{ top: 'sm' }}>
        <Group wrap={false} valign="start" gap="xl" fullWidth fullHeight>
          <Group.Item flex>{renderEntity(data, { hierarchyId: '' })}</Group.Item>
          <Group.Item flex>
            <MultiTabSection
              isInEditMode={isInEditMode}
              isRecentlyPublish={isRecentlyPublish}
              isRecentlyUpdated={isRecentlyUpdated}
              onUpdateName={handleUpdateName}
              handleShowHistoryEditMode={showEditSchedule}
              savedForScheduleEdit={savedForScheduleEdit}
            />
          </Group.Item>
        </Group>
      </Wrapper>
    </>
  );
};
