import { useMemo } from 'react';
import { Button, IconExternalLink } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { buildLiveCoverageUrl, buildNewspressUrl } from 'utils/cms';

interface OpenInNewsPressProps {
  content: AllessehContent;
}

export const OpenInNewsPress = ({ content }: OpenInNewsPressProps) => {
  const newspressUrl = useMemo(
    () => buildNewspressUrl(content.data.id, content.data.attributes.type),
    [content.data.id, content.data.attributes.type]
  );

  const liveCoverageUrl = useMemo(
    () => buildLiveCoverageUrl(content.data.attributes.source_url, content.data.attributes.product),
    [content.data.attributes.source_url, content.data.attributes.product]
  );

  const handleViewNewsPressUrl = () => {
    if (!newspressUrl) return;
    window.open(newspressUrl, '_blank');
  };

  const handleViewLiveCoverageUrl = () => {
    if (!liveCoverageUrl) return;
    window.open(liveCoverageUrl, '_blank');
  };

  const isLiveCoverage = content.data.attributes.product.includes('Live Coverage');
  return newspressUrl ? (
    <Button
      data-testid="newspress-button-open-cms-button"
      tertiary
      icon={IconExternalLink as SvgComponent}
      iconPosition="right"
      onClick={handleViewNewsPressUrl}
    >
      Open in CMS
    </Button>
  ) : isLiveCoverage ? (
    <Button
      data-testid="newspress-button-open-cms-button"
      tertiary
      icon={IconExternalLink as SvgComponent}
      iconPosition="right"
      onClick={handleViewLiveCoverageUrl}
    >
      Open in CMS
    </Button>
  ) : null;
};
