import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Tabs as TabsComponent, Wrapper } from '@screentone/core';
import isEqual from 'lodash.isequal';

import { Article } from 'components/datamodel/content/Article/Article';
import { HistoryContent, PublishHistory } from 'components/publish-history/PublishHistory';
import { SearchParams } from 'components/search-content/SearchContent';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { useDragAndDrop } from 'contexts/drag-and-drop/useDragAndDrop';
import {
  OffPlatformDto,
  OffPlatformListContainer,
  PublicationSettingSearchableContentType
} from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes } from 'hooks/useAllessehContentQuery';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import styles from '../../OffPlatformEdit.module.scss';
import ContentSearch from '../off-platform-collection-curate-section/OffPlatformCollectionCurateSection';
import SettingsSection from '../off-platform-collection-settings-section/OffPlatformCollectionSettingsSection';

interface MultiTabSectionProps {
  isInEditMode: boolean;
  isRecentlyPublish: boolean;
  isRecentlyUpdated: boolean;
  onUpdateName: (name: string) => void;
  handleShowHistoryEditMode: (historyItem: OffPlatformDto) => void;
  savedForScheduleEdit: OffPlatformDto | null;
}

enum Tabs {
  ContentSearch,
  PublishHistory,
  Settings
}
const MultiTabSection = ({
  isInEditMode,
  isRecentlyPublish,
  isRecentlyUpdated,
  onUpdateName,
  handleShowHistoryEditMode,
  savedForScheduleEdit
}: MultiTabSectionProps) => {
  const {
    root: data,
    metadata,
    getDTO,
    fromAllessehContent,
    getAllArticleIds
  } = useDataModelContext<OffPlatformListContainer>();
  const offPlatform = getDTO() as OffPlatformDto;
  const [liveVersion, setLiveVersion] = useState(offPlatform);

  const [tabIndex, setTabIndex] = useState(Tabs.ContentSearch);

  useEffect(() => {
    setLiveVersion(offPlatform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecentlyPublish]);

  const { generateDraggableId } = useDragAndDrop();
  const { renderActions } = useContextMenuActions();
  const { data: publicationSettingsResp } = usePublicationSettings();

  const usedIds = getAllArticleIds();

  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: usedIds
    }),
    [usedIds, publicationSettingsResp]
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: AllessehQuerySortKeyTypes.LiveDate
  });

  useEffect(() => {
    if (publicationSettingsResp) {
      setSearchParams((currentParams) => ({
        ...currentParams,
        filters: {
          ...currentParams.filters,
          contentTypes: publicationSettingsResp.publicationSetting.defaultContentTypes
        }
      }));
    }
  }, [publicationSettingsResp]);

  useEffect(() => {
    if (searchParams.filters.contentTypes.length > 0 && !isEqual(searchParams.filters.excludedContentIds, usedIds)) {
      setSearchParams((currentParams) => ({
        ...currentParams,
        filters: {
          ...currentParams.filters,
          excludedContentIds: usedIds
        }
      }));
    }
  }, [usedIds, searchParams]);

  const hierarchyId = `0--${data.collection.length}`;

  return (
    <Wrapper>
      <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="off-platform-content-search-tab"
        >
          Content Search
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.PublishHistory ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          data-testid="off-platform-publish-history"
        >
          Publish History
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
        >
          Settings
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.ContentSearch && (
        <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
          <ContentSearch
            searchProps={{
              defaultFilterState,
              searchParams,
              resultListClassName: styles.curateScrollable,
              onSearchParamsChange: setSearchParams,
              renderContentCard: (content: AllessehContent, index: number) => (
                <Draggable draggableId={generateDraggableId(DRAGGABLE_PREFIXES.MODULE_ITEM, { content })} index={index}>
                  {(draggableProvided) => {
                    const articleItem = fromAllessehContent(content);

                    return (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <Article
                          data={articleItem}
                          renderActions={() =>
                            renderActions(MenuActionsKeys.ArticleSearch, {
                              entity: articleItem,
                              hierarchyId
                            })
                          }
                        />
                      </div>
                    );
                  }}
                </Draggable>
              )
            }}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.PublishHistory && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <PublishHistory<OffPlatformDto>
            liveVersion={liveVersion}
            publishUtc={0}
            hasRecentlyPublished={isRecentlyUpdated}
            originalVersionWhenInHistoryEditMode={savedForScheduleEdit}
            currentlyEditedVersion={isInEditMode ? offPlatform : undefined}
            handleShowHistoryEditMode={(historyItem: HistoryContent) =>
              handleShowHistoryEditMode(historyItem as OffPlatformDto)
            }
            scrollableSectionClassName={styles.historyScrollable}
            showPreview={false}
            // showURL={false}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.Settings && (
        <Wrapper id="tabpanel-id2" role="tabpanel" aria-labelledby="tab-id2" padding={{ top: 'sm' }}>
          <SettingsSection
            name={metadata.name}
            hostingURL={data.attributes.hostingPageURL}
            onUpdateName={onUpdateName}
            isInEditMode={isInEditMode}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default MultiTabSection;
