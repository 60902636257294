import { AllessehContent } from 'hooks/useAllessehContentQuery';

export type AltSummVariant =
  | 'U.S. Home'
  | 'ITP'
  | 'NewsPlus'
  | 'APIImage'
  | 'Mobile'
  | 'SEO'
  | 'Social Open Graph'
  | 'Home-Page';

type ContentId = string;

export type AltSummFields = Partial<{
  proxy: SummarianProxy;
  headline: string;
  body: string;
  image: string;
  chart: string;
  video: string;
  videoImage: string;
  bullets: string[];
  subhead: string;
  chartlosId: string;
  iframe: string;
}>;

type ItemCache = Record<ContentId, AltSummFields> | undefined;

type AltSummCache = Record<AltSummVariant, ItemCache>;

type ReducerState = {
  currentAltSummVariant: AltSummVariant;
  altSummCache: AltSummCache;
  trackedItems: Map<string, AllessehContent>;
};

type SetAltSummVariant = {
  type: 'SET_CURRENT_ALT_SUMM_VARIANT';
  payload: AltSummVariant;
};
type TrackContentItem = {
  type: 'TRACK_CONTENT_ITEM';
  payload: AllessehContent;
};
type UpdateContentItem = {
  type: 'UPDATE_CONTENT_ITEM';
  payload: {
    id: string;
    fields: AltSummFields;
    proxy?: SummarianProxy;
  };
};
type ReducerActions = SetAltSummVariant | TrackContentItem | UpdateContentItem;

export function summarianReducer(state: ReducerState, action: ReducerActions) {
  switch (action.type) {
    case 'SET_CURRENT_ALT_SUMM_VARIANT': {
      return { ...state, currentAltSummVariant: action.payload };
    }
    case 'TRACK_CONTENT_ITEM': {
      const content = action.payload;

      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackedItems: new Map([...state.trackedItems, [content.data.id, content]])
      };
    }
    case 'UPDATE_CONTENT_ITEM': {
      const { id, fields, proxy } = action.payload;
      const defaultProxy = state.altSummCache[state.currentAltSummVariant]?.[id]?.proxy;

      return {
        ...state,
        altSummCache: {
          ...state.altSummCache,
          [state.currentAltSummVariant]: {
            ...state.altSummCache[state.currentAltSummVariant],
            [id]: {
              ...state.altSummCache[state.currentAltSummVariant]?.[id],
              ...fields,
              proxy: proxy ?? defaultProxy
            }
          }
        }
      };
    }
    default:
      return state;
  }
}
