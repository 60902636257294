import React, { useMemo } from 'react';
import { Divider, Wrapper } from '@screentone/core';

import { WidePageWrapper } from 'components';
import { AddAllessehCollection } from 'components/context-menu-actions/add-allesseh-collection/AddAllessehCollection';
import { AddLinkedItem } from 'components/context-menu-actions/add-linked-item/AddLinkedItem';
import { AddQuery } from 'components/context-menu-actions/add-query/AddQuery';
import { ConvertToCollection } from 'components/context-menu-actions/convert-to-collection/ConvertToCollection';
import { DetachFromCollection } from 'components/context-menu-actions/detach-from-collection/DetachFromCollection';
import { EditAltSumm } from 'components/context-menu-actions/edit-alt-summ/EditAltSumm';
import { EditCollection } from 'components/context-menu-actions/edit-collection/EditCollection';
import { EditLinkedItem } from 'components/context-menu-actions/edit-linked-item/EditLinkedItem';
import { EditModule } from 'components/context-menu-actions/edit-module/EditModule';
import { EditQuery } from 'components/context-menu-actions/edit-query/EditQuery';
import { ExcludeFromQuery } from 'components/context-menu-actions/exclude-from-query/ExcludeFromQuery';
import ExternalLink, { ExternalLinkContentType } from 'components/context-menu-actions/external-link/ExternalLink';
import { OpenInNewsGrid } from 'components/context-menu-actions/open-in-newsgrid/OpenInNewsGrid';
import { OpenInNewsPress } from 'components/context-menu-actions/open-in-newspress/OpenInNewsPress';
import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { ContextMenuActionsProvider, MenuActions } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import {
  ArticleItem,
  ExternalCollectionItem,
  LinkedItem,
  ModuleContainer,
  PageModule,
  QueryItem,
  UiModuleType
} from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { usePrompt } from 'hooks/useBeforeLeavePage';
import { safelyParseContent } from 'utils/temp';
import { PageDraftSection } from './components/page-draft-section/PageDraftSection';
import { PageMultitabSection } from './components/page-multitab-section/PageMultitabSection';
import { PageTitleHeader } from './components/page-title-header/PageTitleHeader';
import styles from './SectionPageEdit.module.scss';

export const SectionPageEdit = () => {
  const { removableUiModules } = usePageTypeSettingsContext();
  const { hasModelChanged } = useDataModelContext();
  const contextActions = useMemo<MenuActions>(
    () => ({
      draftArticle: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      draftExternalCollection: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const collection = entity as ExternalCollectionItem;
            return <EditCollection collection={collection} />;
          },
          showIf: ({ extraProperties: { isExternalCollection } = {} }) => !isExternalCollection
        },
        {
          component: ({ hierarchyId, entity }) => {
            const collection = entity as ExternalCollectionItem;
            return <DetachFromCollection hierarchyId={hierarchyId!} collection={collection} />;
          },
          showIf: ({ extraProperties: { isExternalCollection } = {} }) => !isExternalCollection
        }
      ],
      draftQuery: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
          withDivider: 'bottom'
        },
        {
          component: ({ hierarchyId, entity, extraProperties }) => {
            const query = entity as QueryItem;
            return (
              <EditQuery
                hierarchyId={hierarchyId!}
                query={query}
                numItemsToShow={extraProperties!.numItemsToShow!}
                baseQuery={extraProperties!.baseQuery!}
              />
            );
          }
        }
      ],
      draftLinkedItem: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const linkedItem = entity as LinkedItem;
            return <EditLinkedItem linkedItem={linkedItem} />;
          }
        },
        {
          component: ({ entity }) => {
            const linkedItem = entity as LinkedItem;
            return <ViewPublishedURL externalLink={linkedItem.attributes.hosted_url} />;
          }
        }
      ],
      draftModule: [
        {
          component: ({ hierarchyId, entity }) => {
            const module = entity as ModuleContainer;
            return <EditModule hierarchyId={hierarchyId!} module={module} />;
          }
        },
        {
          component: ({ hierarchyId, entity, extraProperties }) => {
            const module = entity as ModuleContainer;
            return (
              <AddQuery
                hierarchyId={hierarchyId!}
                module={module}
                numTotalItemsUsed={extraProperties!.numTotalItemsUsed!}
              />
            );
          },
          showIf: ({ entity }) => {
            const module = entity as ModuleContainer;
            const pageModuleAttribute = module.attributes.pageModule as PageModule;
            return pageModuleAttribute.uiModuleType !== UiModuleType.UiFeedsModuleType;
          }
        },
        {
          component: ({ hierarchyId, entity }) => {
            const module = entity as ModuleContainer;
            return <AddLinkedItem hierarchyId={hierarchyId!} module={module} />;
          },
          showIf: ({ entity }) => {
            const module = entity as ModuleContainer;
            const pageModuleAttribute = module.attributes.pageModule as PageModule;
            return pageModuleAttribute.uiModuleType !== UiModuleType.UiFeedsModuleType;
          }
        },
        {
          component: ({ hierarchyId, entity }) => {
            const module = entity as ModuleContainer;
            return <AddAllessehCollection hierarchyId={hierarchyId!} module={module} />;
          },
          showIf: ({ entity }) => {
            const module = entity as ModuleContainer;
            const pageModuleAttribute = module.attributes.pageModule as PageModule;
            return pageModuleAttribute.uiModuleType !== UiModuleType.UiFeedsModuleType;
          }
        },
        {
          component: ({ hierarchyId, entity }) => {
            const module = entity as ModuleContainer;
            return <ConvertToCollection hierarchyId={hierarchyId!} module={module} />;
          },
          showIf: ({ entity }) => {
            const module = entity as ModuleContainer;
            const pageModuleAttribute = module.attributes.pageModule as PageModule;
            return pageModuleAttribute.uiModuleType !== UiModuleType.UiFeedsModuleType;
          }
        },
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove module" />,
          showIf: ({ entity }) => {
            const module = entity as ModuleContainer;
            const pageModuleAttribute = module.attributes.pageModule as PageModule;
            const removableUiModuleTypes = removableUiModules.map(
              (availableUiModule) => availableUiModule.uiModuleType
            );
            return removableUiModuleTypes.includes(pageModuleAttribute.uiModuleType);
          },
          withDivider: 'top'
        }
      ],
      historyArticle: [
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      historyExternalCollection: [
        {
          component: ({ entity }) => {
            const collection = entity as ExternalCollectionItem;
            return <EditCollection collection={collection} />;
          },
          showIf: ({ extraProperties: { isExternalCollection } = {} }) => !isExternalCollection
        }
      ],
      historyQuery: [
        {
          component: ({ hierarchyId, entity, extraProperties }) => {
            const query = entity as QueryItem;
            return (
              <EditQuery
                hierarchyId={hierarchyId!}
                query={query}
                numItemsToShow={extraProperties!.numItemsToShow!}
                baseQuery={extraProperties!.baseQuery!}
                viewOnly
              />
            );
          }
        }
      ],
      historyLinkedItem: [
        {
          component: ({ entity }) => {
            const linkedItem = entity as LinkedItem;
            return <ViewPublishedURL externalLink={linkedItem.attributes.hosted_url} />;
          }
        }
      ],
      historyModule: [
        {
          component: ({ hierarchyId, entity }) => {
            const module = entity as ModuleContainer;
            return <EditModule hierarchyId={hierarchyId!} module={module} viewOnly />;
          }
        }
      ],
      articleSearch: [
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      articleQueryModal: [
        {
          component: ({ extraProperties }) => (
            <ExcludeFromQuery
              jsonQueryStr={extraProperties!.jsonQueryStr!}
              allessehContent={extraProperties!.allessehContent!}
              setJsonQueryStr={extraProperties!.setJsonQueryStr!}
            />
          ),
          showIf: ({ isHistory }) => !isHistory,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        }
      ]
    }),
    [removableUiModules]
  );

  usePrompt('You have unsaved changes. Are you sure you want to leave?', hasModelChanged);

  return (
    <PageRootContainer>
      <WidePageWrapper padding={{ all: 'md' }}>
        <ContextMenuActionsProvider actions={contextActions}>
          <PageTitleHeader data-testid="page-home-title" />
          <Divider className={styles.horizontalDivider} />
          <Wrapper margin={{ top: 'sm' }}>
            <div className={styles.pageEditRow}>
              <div className={styles.pageEditDraft}>
                <PageDraftSection />
              </div>
              <div className={styles.pageDivider} />
              <div className={styles.pageEditSearch}>
                <PageMultitabSection />
              </div>
            </div>
          </Wrapper>
        </ContextMenuActionsProvider>
      </WidePageWrapper>
    </PageRootContainer>
  );
};
