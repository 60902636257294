import { useMemo } from 'react';
import { Button, IconRefresh, Token, Typography, Wrapper } from '@screentone/core';

import useSearchContent from 'components/search-content/hooks/useSearchContent';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from './SearchByQueryTerm.module.scss';
import { SearchContentByProps } from '../search-by-url/SearchByUrl';
import SearchContentList from '../search-content-list/SearchContentList';

interface SearchByQueryTermParams extends SearchContentByProps {
  handleRefresh?: () => void;
  embargo?: boolean;
}
const SearchByQueryTerm = ({
  searchParams,
  resultListClassName,
  renderContentCard,
  handleRefresh,
  embargo
}: SearchByQueryTermParams) => {
  const {
    data: searchData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isLoad,
    fetchStatus
  } = useSearchContent(searchParams);

  const isLoading = isLoad && fetchStatus !== 'idle';

  const { pages } = searchData ?? {};
  const initContentItems = useMemo(
    () =>
      (pages
        ?.map((page) => page.data?.attributes)
        .flat()
        .filter((content) => !searchParams.filters.excludedContentIds?.includes(content?.data.id ?? '')) ??
        []) as AllessehContent[],
    [pages, searchParams.filters.excludedContentIds]
  );

  const nextPageProps = useMemo(
    () => ({
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage
    }),
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  const resultCount = searchData?.pages[0]
    ? searchData.pages[0]?.links?.total && searchData.pages[0].links.total > 100
      ? '100+'
      : searchData.pages[0]?.links?.total
    : 0;

  const onClickRefresh = () => {
    if (handleRefresh) {
      handleRefresh();
    }
  };

  return (
    <>
      {!embargo && (
        <Wrapper className={styles.searchRefresh}>
          <Typography variant="label3" margin={{ vertical: 'xs' }}>
            Results
          </Typography>
          <Token color="gray" margin={{ right: 'sm' }}>
            {resultCount}
          </Token>
          <Button
            size="sm"
            tertiary
            icon={IconRefresh as SvgComponent}
            className={styles.searchRefreshButton}
            onClick={onClickRefresh}
          >
            {' '}
            Refresh
          </Button>
        </Wrapper>
      )}
      <SearchContentList
        contentItems={initContentItems}
        className={resultListClassName}
        nextPageProps={nextPageProps}
        isLoading={isLoading}
        renderContentCard={renderContentCard}
      />
    </>
  );
};

export default SearchByQueryTerm;
