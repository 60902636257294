import { useEffect } from 'react';
import { Button, IconEdit, Tooltip, Wrapper } from '@screentone/core';

import { AltSummModal } from 'components/altsumm-modal/AltSummModal';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from './EditAltSumm.module.scss';

interface EditAltSummProps {
  content: AllessehContent;
  className?: string;
}

const summarianNotReadyMessage = `There was an issue loading Summarian or you do not have Summarian access.
Please contact the #curation team on Slack.`;
const liveCoverageDisabledMessage = 'Edit Alt-Summ is not available for Live Coverage';

export const EditAltSumm = ({ content, className }: EditAltSummProps) => {
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();
  const isLiveCoverage = content.data.attributes.product.includes('Live Coverage');

  const handleEditAltSumm = () => {
    setIsModalOpen(true);
    setActionModalComponent(<AltSummModal content={content} />);
  };

  useEffect(() => {
    if (window.isSummarianReady) {
      setActionModalComponent(<AltSummModal content={content} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActionModalComponent]);

  const publicationSettings = publicationSettingsResp?.publicationSetting;
  const shouldDisplay = content.data.attributes.type !== 'commerceproduct';
  const disableAltSummEdit = !!publicationSettings?.disableAltSummEdit;
  if (!shouldDisplay || disableAltSummEdit) {
    return null;
  }

  const EditAltSummButton = (
    <Wrapper className={className}>
      <Button
        data-testid="edit-alt-summ-option-button"
        disabled={isLiveCoverage || !window.isSummarianReady}
        tertiary
        icon={IconEdit as SvgComponent}
        onClick={handleEditAltSumm}
      >
        Edit Alt-Summ
      </Button>
    </Wrapper>
  );

  return (
    <>
      {window.isSummarianReady ? (
        EditAltSummButton
      ) : (
        <Tooltip data-testid="edit-alt-summ-option-tooltip">
          <Tooltip.Content
            data-testid="edit-alt-summ-option-tooltip-content"
            id="altSumModalTooltip"
            position="bottom"
            className={styles.altSumTooltip}
            size="lg"
          >
            {isLiveCoverage ? liveCoverageDisabledMessage : summarianNotReadyMessage}
          </Tooltip.Content>
          <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
            {EditAltSummButton}
          </Tooltip.Trigger>
        </Tooltip>
      )}
    </>
  );
};
