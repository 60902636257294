import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, IconArchive, IconCopy, IconPackage, IconTrash, Loader } from '@screentone/core';

import { ConfirmationDialog } from 'components';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { useAlert } from 'contexts/alert/useAlert';
import {
  Collection,
  PagesDtoForCollectionIdQuery,
  useCollectionArchiveMutation,
  useCollectionCopyMutation,
  useCollectionOffPlatformConvertMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { useDeleteCollection } from 'hooks/collections/useDeleteCollection';
import { useIsAdmin } from 'hooks/roles';
import styles from './CollectionPublishingButtons.module.scss';
import {
  CollectionOffPlatformOverlay,
  TOffPlatformInput
} from '../collection-off-platform-overlay/CollectionOffPlatformOverlay';

interface CollectionPublishingButtonsProps {
  collection: Collection;
  hasCollectionChanged: boolean;
  pagesForCollection?: PagesDtoForCollectionIdQuery;
}

export const CollectionPublishingButtons = ({
  collection,
  hasCollectionChanged,
  pagesForCollection
}: CollectionPublishingButtonsProps) => {
  const navigate = useNavigate();
  const { currentProperty } = useAuth();
  const convertedProperty = useConvertedProperty();
  const { alertError } = useAlert();
  const isAdmin = useIsAdmin();
  const [offPlatformInputOpen, setOffPlatformInputOpen] = useState(false);
  const [waitingForAllesseh, setWaitingForAllesseh] = useState(false);

  const { mutateAsync: collectionCopyMutateAsync, isLoading: isCopying } = useCollectionCopyMutation();
  const { mutateAsync: collectionArchiveMutateAsync, isLoading: isArchiving } = useCollectionArchiveMutation();
  const { mutateAsync: collectionOffPlatformConvert, isLoading: isConverting } =
    useCollectionOffPlatformConvertMutation();
  const { showDeleteModal, setShowDeleteModal, canDelete, deleteHint, isDeleteLoading, handleDeleteCollection } =
    useDeleteCollection({
      collectionId: collection.allessehCollectionId,
      variant: 'collections',
      pagesForCollection
    });

  const handleArchive = async () => {
    try {
      await collectionArchiveMutateAsync({
        collectionArchiveInput: {
          id: collection.id,
          allessehCollectionId: collection.allessehCollectionId,
          createdUtc: collection.createdUtc,
          isArchived: !collection.isArchived,
          publicationKey: convertedProperty ?? ''
        }
      });
      window.location.reload();
    } catch (e: unknown) {
      alertError('Could not archive the collection. Please try again.');
    }
  };

  const handleCopy = async () => {
    try {
      const resp = await collectionCopyMutateAsync({
        collectionCopyInput: {
          id: collection.id,
          allessehCollectionId: collection.allessehCollectionId,
          createdUtc: collection.createdUtc,
          publicationKey: convertedProperty ?? ''
        }
      });
      navigate(`/${currentProperty ?? ''}/collections/${resp.collectionCopy.idCreatedUtc}`);
    } catch (e: unknown) {
      alertError('Could not update the collection. Please try again.');
    }
  };

  const handleOffPlatformConvert = async (offPlatformInput: TOffPlatformInput) => {
    try {
      setWaitingForAllesseh(true);
      const resp = await collectionOffPlatformConvert({
        collectionOffPlatformInput: {
          allessehCollectionId: collection.allessehCollectionId,
          publicationKey: convertedProperty ?? '',
          ...offPlatformInput
        }
      });

      /*
        TODO: This is a temp fix for ET-5557

        Adds a 5 sec timer before off-platform redirect.
        Allesseh's processing time may vary, this will
        probably need further adjustment.
        See: PagePublishContext TODO comment for potential implementation
      */
      setTimeout(() => {
        setWaitingForAllesseh(false);
        navigate(`/${currentProperty ?? ''}/off-platform/${resp.collectionOffPlatformConvert.idCreatedUtc}`);
      }, 5000);
    } catch (e: unknown) {
      let message = 'Could not convert to off-platform. Please try again.';

      if (e instanceof Error) message = e.message;

      alertError(message);
    }
  };

  return (
    <Box data-testid="collection-info-box-section-options" margin={{ top: 'md' }}>
      <Box.Title data-testid="collection-info-box-section-options-title">Options</Box.Title>
      <Box.Content data-testid="collection-info-box-section-options-content" className={styles.optionsButtons}>
        <Button
          tertiary
          icon={IconArchive as SvgComponent}
          disabled={isArchiving}
          margin={{ right: 'md' }}
          onClick={handleArchive}
          data-testid="collection-info-box-section-options-archive-button"
        >
          {collection.isArchived ? 'Unarchive' : 'Archive'}
        </Button>
        {isAdmin && (
          <Button
            tertiary
            icon={IconTrash as SvgComponent}
            color="lava"
            data-testid="collection-info-box-section-options-delete-button"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        )}
        <Button
          tertiary
          icon={IconCopy as SvgComponent}
          disabled={isCopying}
          onClick={handleCopy}
          data-testid="collection-info-box-section-options-make-copy-button"
        >
          Make a copy
        </Button>
        {isAdmin && (
          <Button
            tertiary
            icon={IconPackage as SvgComponent}
            disabled={collection.isArchived || hasCollectionChanged || isConverting || waitingForAllesseh}
            onClick={() => setOffPlatformInputOpen(true)}
            data-testid="collection-info-box-section-options-convert-off-platform-button"
          >
            {(isConverting || waitingForAllesseh) && <Loader size="md" margin={{ right: 'md' }} />}
            Convert to Off-Platform
          </Button>
        )}
        <ExternalLink
          contentId={collection.allessehCollectionId}
          type={ExternalLinkContentType.COLLECTION}
          isPublished={!!collection.updatedUtc}
          data-testid="collection-info-box-section-options-buttons-container"
          margin={{ right: 'md' }}
        />
      </Box.Content>
      {showDeleteModal && (
        <ConfirmationDialog
          title="Delete Collection"
          description="Are you sure you want to delete this collection?"
          showDialog={setShowDeleteModal}
          onClickFunc={handleDeleteCollection}
          isLoading={isDeleteLoading}
          hint={deleteHint}
          disabled={!canDelete}
        />
      )}
      {offPlatformInputOpen && (
        <CollectionOffPlatformOverlay
          currentProperty={currentProperty}
          onDismiss={() => setOffPlatformInputOpen(false)}
          onConvert={handleOffPlatformConvert}
        />
      )}
    </Box>
  );
};
