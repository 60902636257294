import { cloneDeep } from 'lodash';

import {
  SnippetyChartData,
  SnippetyResponseWithChart,
  SnippetyResponseWithImage,
  SnippetyVideoData,
  VideoDataForSummarian
} from 'hooks/useSnippetyQuery';

export type MediaTypes = 'image' | 'video' | 'chart';

export const IMAGE_MANAGER_ID_PATTERN = /^im-\d+$/;

export const CHART_ID_PATTERN = /^(cdc_[0-9A-Fa-f]{24}|[0-9A-Fa-f]{24})$/;

export const GUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const errorMessages = {
  image: {
    invalIdInput: 'You must enter an image ID',
    invalidId: 'Invalid entry, please enter a valid image ID',
    serverError: 'An error has occurred while trying to fetch the image data'
  },
  video: {
    invalIdInput: 'You must enter a video GUID',
    invalidId: 'Invalid entry, please enter a valid video GUID',
    serverError: 'An error has occurred while trying to fetch the video data'
  },
  chart: {
    invalIdInput: 'You must enter a chart ID',
    invalidId: 'Invalid entry, please enter a valid chart ID',
    serverError: 'An error has occurred while trying to fetch the chart data'
  }
};

export function getImageData(imageData: SnippetyResponseWithImage): SnippetyResponseWithImage {
  const clonedImageData = cloneDeep(imageData);
  const defaultBaseUrl = new URL(clonedImageData.url).origin;

  return {
    ...clonedImageData.tools.image,
    tools: clonedImageData.tools,
    desc: clonedImageData.desc,
    provider: clonedImageData.provider,
    media: clonedImageData.media,
    href: clonedImageData.tools.image.href ?? clonedImageData.url,
    src: {
      ...clonedImageData.tools.image.src,
      baseUrl: clonedImageData.tools.image.src?.baseUrl ?? defaultBaseUrl,
      imageId: clonedImageData.tools.image.src?.imageId,
      path: clonedImageData.tools.image.src?.path
    }
  };
}

export function getVideoData(videoData: SnippetyVideoData): VideoDataForSummarian {
  return {
    alt: videoData.title || '',
    aspectRatio: 1.7778, // Default 16:9 aspect ratio
    author: videoData.author || 'Unknown Author',
    authorName: videoData.author || '',
    caption: videoData.desc || 'No description available.',
    compat: { item: '', meta: '' },
    context: '',
    date: Date.now() / 1000, // Current timestamp as default
    dateFormatted: '',
    dateModifiedFormatted: new Date().toLocaleString(), // Current formatted date
    describe: true,
    description: videoData.desc || 'No description available.',
    editLink: '',
    filename: videoData.title || 'Untitled',
    filesizeHumanReadable: 'Unknown',
    filesizeInBytes: 0,
    format: 'standard',
    guid: videoData.id,
    height: 720, // Assuming video dimensions
    icon: '/wp-includes/images/media/default.png', // Default placeholder icon
    menuOrder: 0,
    meta: false,
    mime: 'image/jpeg',
    modified: Date.now() / 1000, // Current timestamp
    name: videoData.title || 'Untitled',
    nonces: { delete: '', edit: '', update: '' },
    orientation: 'landscape',
    search: '',
    sizes: {
      full: {
        height: 720,
        orientation: 'landscape',
        url: videoData.media || '',
        width: 1280
      }
    },
    status: 'inherit',
    subtype: 'jpeg',
    title: videoData.title || 'Untitled',
    type: 'image',
    uploadedTo: 0,
    uploadedToLink: '',
    uploadedToTitle: '',
    url: videoData.media || '',
    width: 1280
  };
}

export function getChartData(chartData: SnippetyResponseWithChart): SnippetyChartData {
  return { media: chartData.media, ...chartData.tools.chart };
}
